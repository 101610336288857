import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Button, Container, Form, Row, Col, Dropdown } from 'react-bootstrap';
import { useMember } from '../../context/member-context'

function AdminDashboard() {
  const member = useMember();
  
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    (async () => {
      if (member.role !== 'admin') return setErrorMessage('You are not authorized to access this page')
    })()
  }, [])

  if (member.role !== 'admin') return <div>{errorMessage}</div>

  return(
    <div>
      <Container fluid>
        <h1>Admin</h1>
        <Link to='/admin/companies'>Manage Companies</Link><br/>
        <Link to='/admin/members'>Manage Members</Link><br/>
        <Link to='/admin/members/sessions'>Member Logins/Logouts</Link><br/>
      </Container>
    </div>
  )
}

export default AdminDashboard;
