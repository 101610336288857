// === Poly Fills for IE11+ ===
import 'react-app-polyfill/stable';
import 'react-app-polyfill/ie11';
import 'url-search-params-polyfill';
import 'abortcontroller-polyfill';

import React from 'react';
import ReactDOM from 'react-dom';

// === Oldschool Scripts ===
import 'jquery';
import 'popper.js';
import 'bootstrap';
import 'metismenu';

// === Oldschool CSS ===
import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
import 'animate.css/animate.min.css';
import './theme/assets/css/inspinia.css';
import './theme/assets/css/custom.css';

// === App ===
import AppProviders from './context';
import App from './App';

ReactDOM.render(
  <AppProviders>
    <App />
  </AppProviders>,
  document.getElementById('root')
);
