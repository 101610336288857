import { client } from './client';

function getMemberWatchlists() {
  return client('watchlist/member');
}

function getWatchlistDetails(watchListId) {
  return client(`watchlist/details/${watchListId}`);
}

function createWatchlist(name) {
  return client('watchlist/create', { body: { name } });
}

function deleteWatchlist(watchListId) {
  return client('watchlist/delete', { body: { watchListId } });
}

function addPartToWatchlist(watchListId, partId) {
  return client('watchlist/add-part', { body: { partId, watchListId } });
}

function removePartFromWatchlist(watchListId, partId) {
  return client('watchlist/remove-part', { body: { partId, watchListId } });
}

export {
  getMemberWatchlists,
  getWatchlistDetails,
  createWatchlist,
  removePartFromWatchlist,
  addPartToWatchlist,
  deleteWatchlist
};
