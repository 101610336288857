import { client } from './client';

function getPartOverview(partNumber) {
  return client(`part/overview/${partNumber}`);
}

function getPartFMVSummary(partNumber) {
  return client(`part/fmv-summary/${partNumber}`);
}

function getPartHotTips(partNumber) {
  return client(`part/hot-tips/${partNumber}`);
}

function getPartPricingHistory({ partNumber, conditionCode, transactionType, dateRange, shared }) {
  if (shared)
    return client(`part/shared-pricing-history`, { body: { partNumber, conditionCode, transactionType, dateRange } });

  return client(`part/pricing-history`, { body: { partNumber, conditionCode, transactionType, dateRange } });
}

function getPartVolumeHistory({ partNumber, conditionCode, transactionType }) {
  return client('part/volume-history', { body: { partNumber, conditionCode, transactionType } });
}

function getTopTransactedParts() {
  return client('part/top-parts');
}

export {
  getPartOverview,
  getPartFMVSummary,
  getPartHotTips,
  getPartPricingHistory,
  getPartVolumeHistory,
  getTopTransactedParts
};
