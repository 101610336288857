import React, { useState, useEffect } from 'react';
import { Row, Col, Table, Image } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { client } from '../../api/client';
import { Card, CardBody } from '../../components/Card';
import { useMember } from '../../context/member-context';
import ReactGA from 'react-ga';
import moment from 'moment';

function ManagedMember() {
  const member = useMember();
  const [managedMember, setManagedMember] = useState({});
  const [errorMessage, setErrorMessage] = useState('');

  let { id } = useParams();
  if (typeof id === 'undefined') id = '';

  useEffect(() => {
    if (member) {
      ReactGA.initialize({
        trackingId: process.env.REACT_APP_GA_TRACKING_ID,
        title: 'Managed Member Profile',
        pageview: window.location.pathname,
        gaOptions: {
          username: member.username
        }
      });
    }
  }, [member]);

  useEffect(() => {
    (async () => {
      if (member.role !== 'manager') return setErrorMessage('You are not authorized to access this page');
      const data = await client(`manager/member/${id}`);
      if (data.error) return setErrorMessage(data.message);

      setManagedMember(data.managedMember);
    })();
  }, [member, id]);

  if (member.role !== 'manager') return <div>{errorMessage}</div>;

  const company = typeof managedMember.companyId === 'object' ? managedMember.companyId : null;
  const department = typeof managedMember.deptId === 'object' ? managedMember.deptId : null;
  const departmentManager = typeof managedMember.parentId === 'object' ? managedMember.parentId : null;

  return (
    <Row>
      <Col md={{ span: 8, offset: 2 }} lg={{ span: 6, offset: 3 }}>
        <Card>
          <CardBody>
            <Row>
              <Col>
                <h3 className="text-center">{`${managedMember.firstName + ' ' + managedMember.lastName}`}</h3>
                <div className="text-center mb-3">
                  <Image src="https://via.placeholder.com/150" roundedCircle alt="Profile" />
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <Table size="sm" responsive>
                  <tbody>
                    <tr>
                      <th className="bg-active">First Name</th>
                      <td>{managedMember.firstName}</td>
                    </tr>
                    <tr>
                      <th className="bg-active">Last Name</th>
                      <td>{managedMember.lastName}</td>
                    </tr>
                    <tr>
                      <th className="bg-active">Email</th>
                      <td>{managedMember.email}</td>
                    </tr>
                    <tr>
                      <th className="bg-active">Phone</th>
                      <td>{managedMember.phone ? managedMember.phone : '-'}</td>
                    </tr>
                    <tr>
                      <th className="bg-active">Registration Date</th>
                      <td>
                        {moment(managedMember.registrationDate)
                          .format('DDMMMYYYY')
                          .toUpperCase()}
                      </td>
                    </tr>
                    <tr>
                      <th className="bg-active">Company Name</th>
                      <td>{company ? company.name : '-'}</td>
                    </tr>
                    <tr>
                      <th className="bg-active">Department Name</th>
                      <td>{department ? department.name : '-'}</td>
                    </tr>
                    <tr>
                      <th className="bg-active">Department Manager</th>
                      <td>
                        {departmentManager ? (
                          <ul className="list-unstyled">
                            <li>{`${managedMember.parentId.firstName + ' ' + managedMember.parentId.lastName}`}</li>
                            <li>
                              <a href={`mailto:${managedMember.parentId.email}`}>{managedMember.parentId.email}</a>
                            </li>
                          </ul>
                        ) : (
                          '-'
                        )}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
}

export default ManagedMember;
