import React, { useState } from 'react';
import { CardElement, injectStripe } from 'react-stripe-elements';
import './PaymentForm.css';
import { client } from '../../api/client'
import { Link, useParams } from 'react-router-dom';

function ChangeCardForm({ stripe }) {
  const [status, setStatus] = useState('default');
  const [company, setCompany] = useState('')
  const [dept, setDept] = useState('')
  const [member, setMember] = useState({})
  const [errorMessage, setErrorMessage] = useState('')

  const { id } = useParams()

  React.useEffect(() => {
    (async () => {
      const data = await client(`admin/member/${id}`)
      
      if (data.error) return setErrorMessage(data.message)    
      setMember(data.member)
      setCompany(data.member.company)
      setDept(data.member.dept)
    })()
  }, [])

  const submit = async e => {
    e.preventDefault();

    setStatus('submitting');

    try {
      const { token } = await stripe.createToken({ name: 'Name' });

      const data = await client('admin/payment/change-card', { body: { token: token.id, memberId: id }} )

      if (!data.error) {
        setStatus('complete');
        // returned data
        // memberId
        // customerId - Stripe
        // cardId - Stripe
        // cardType
        // cardNumber - last 4 digits
        // expirationMonth
        // expirationYear
        // postalCode
      } else {
        throw new Error('Network response was not ok.');
      }
    } catch (err) {
      setStatus('error');
    }
  };

  if (status === 'complete') {
    return <div className="PaymentForm-complete">Card updated successfully</div>;
  }

  return (
    <form className="PaymentForm" onSubmit={submit}>
      <div style={{ height: '25px' }}>{ errorMessage && errorMessage }</div>

      <div>Paying Manager: { member.firstName + ' ' + member.lastName}</div>
      <div>Company: { company }</div>
      <div>Dept: { dept }</div>

      <CardElement />
      <button className="PaymentForm-button" type="submit" disabled={status === 'submitting'}>
        {status === 'submitting' ? 'Submitting' : 'Update Card'}
      </button>
      {status === 'error' && <div className="PaymentForm-error">Something went wrong.</div>}
    </form>
  );
}

export default injectStripe(ChangeCardForm);
