import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import Progress from './components/Progress';
import Navigation from './components/Navigation';
import TopHeader from './components/TopHeader';
import Footer from './components/Footer';
import Dashboard from './pages/Dashboard';
import Payment from './pages/Payment/Payment';
import ChangeCard from './pages/Payment/ChangeCard';
import ChangePlan from './pages/Payment/ChangePlan';
import AddCard from './pages/Payment/AddCard';
import PartDetail from './pages/PartDetail/PartDetail';
import PartsExplorer from './pages/PartsExplorer';
import AdminDashboard from './pages/Admin/Dashboard';
import AdminCompanies from './pages/Admin/Companies';
import AdminCompany from './pages/Admin/Company';
import AdminDeptUpdate from './pages/Admin/DeptUpdate';
import AdminCreateMember from './pages/Admin/CreateMember';
import AdminCompanyMembers from './pages/Admin/CompanyMembers';
import AdminMember from './pages/Admin/Member';
import AdminMembersSessions from './pages/Admin/MembersSessions';
import AdminAllMembers from './pages/Admin/AllMembers';
import Upload from './pages/Upload';
import MemberRoutes from './pages/Member/routes';
import Licenses from './pages/Manager/Licenses'
import ManagedMembers from './pages/Manager/ManagedMembers'
import ManagedMember from './pages/Manager/ManagedMember'
import SharedPart from './pages/SharedPart';
import ErrorPage from './pages/ErrorPage'
import PackageEditor from './pages/PackageEditor';

function AuthenticatedApp() {
  return (
    <main id="wrapper">
      <Progress />
      <Navigation />
      <div id="page-wrapper" className="gray-bg">
        <TopHeader />
        <Switch>
          <Route path="/" exact={true} component={Dashboard} />
          <Route path="/part/:partNumber" component={PartDetail} />
          <Route path="/package-editor" component={PackageEditor} />
          <Route path="/parts-explorer" component={PartsExplorer} />
          <Route path="/upload" exact={true} component={Upload} />
          <Route path="/manager/licenses" exact={true} component={Licenses} />
          <Route path="/manager/members" component={ManagedMembers} />
          <Route path="/admin/members/sessions" exact={true} component={AdminMembersSessions} />
          <Route path="/manager/member/:id" component={ManagedMember} />
          <Route path="/admin" exact={true} component={AdminDashboard} />
          <Route path="/admin/companies" exact={true} component={AdminCompanies} />
          <Route path="/admin/members/:id?" exact={true} component={AdminAllMembers} />
          <Route path="/admin/payment/:id" component={Payment} />
          <Route path="/admin/add-card" component={AddCard} />
          <Route path="/admin/change-card/:id" component={ChangeCard} />
          <Route path="/admin/change-plan/:id" component={ChangePlan} />
          <Route path="/admin/company/:id" exact={true} component={AdminCompany} />
          <Route path="/admin/company-members/:id" exact={true} component={AdminCompanyMembers} />
          <Route path="/admin/dept-update/:id" exact={true} component={AdminDeptUpdate} />
          <Route path="/admin/create-member/" exact={true} component={AdminCreateMember} />
          <Route path="/admin/member/:id" exact={true} component={AdminMember} />
          <Route path="/member" component={MemberRoutes} />
          <Route path="/shared/:id" component={SharedPart} />
          <Route path="*">
            <ErrorPage />
          </Route>
        </Switch>
        <Footer />
      </div>
    </main>
  );
}

export default AuthenticatedApp;
