import React from 'react';
import { Card, CardTitle, CardBody } from '../../components/Card';
import { formatNumberWithCommas, formatNumberToDollarAmount } from './shared';

function generateFMVTitle({ volume, std }) {
  if (volume && std) {
    return (
      'Average Price based on ' +
      formatNumberWithCommas(volume) +
      ' Transactions with Standard Deviation of +/- $' +
      formatNumberWithCommas(Math.round(std.toFixed(2)))
    );
  } else {
    return 'No Data';
  }
}

function FairMarketValueSummary({ data }) {
  const { error, message, payload } = data;

  if (error) {
    return (
      <Card className="m-b-md">
        <CardTitle>
          <h5>Fair Market Value</h5>
        </CardTitle>
        <CardBody>{message}</CardBody>
      </Card>
    );
  }

  const { repairs, sales } = payload;
  return (
    <Card className="m-b-md">
      <CardTitle>
        <h5>Fair Market Value</h5>
      </CardTitle>
      <CardBody>
        <table className="table table-bordered">
          <thead>
            <tr className="bg-active">
              <th>Transaction Type</th>
              <th>NS</th>
              <th>OH</th>
              <th>SV</th>
              <th>AR</th>
              <th>BER</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Sales</td>
              {sales
                .sort((a, b) => a.ordinal - b.ordinal)
                .map(saleFMV => {
                  return (
                    <td key={`saleFMV-${saleFMV.conditionCode}`} title={generateFMVTitle(saleFMV)}>
                      {formatNumberToDollarAmount(saleFMV.value)}
                    </td>
                  );
                })}
            </tr>
            <tr>
              <td>Repairs</td>
              {repairs
                .sort((a, b) => a.ordinal - b.ordinal)
                .map(repairFMV => {
                  return (
                    <td key={`repairFMV-${repairFMV.conditionCode}`} title={generateFMVTitle(repairFMV)}>
                      {formatNumberToDollarAmount(repairFMV.value)}
                    </td>
                  );
                })}
            </tr>
          </tbody>
        </table>
      </CardBody>
    </Card>
  );
}

export default FairMarketValueSummary;
