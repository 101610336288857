import React from "react";
import { Link, useLocation } from "react-router-dom";

export default props => {
  let active = false
  const currentPage = useLocation().pathname
  if (currentPage === props.path) active = true
  if (currentPage.indexOf('/admin') >= 0 && props.path.indexOf('/admin') >= 0) active = true

  return (<li className={active ? "active" : ""}>
    <Link to={props.path}>
      {props.icon && <i className={`fa fa-${props.icon}`}></i>}
      {props.tree ? (
        props.label
      ) : (
        <span className="nav-label">{props.label}</span>
      )}
    </Link>
  </li>)
};
