import React, { useState } from 'react';
import ChangePlanForm from './PaymentForm';
import { client } from '../../api/client'
import { Link, useParams } from 'react-router-dom';

function ChangePlan() {

  const [status, setStatus] = useState('default');
  const [planId, setPlanId] = useState('')
  const [amount, setAmount] = useState('')
  const [company, setCompany] = useState('')
  const [dept, setDept] = useState('')
  const [member, setMember] = useState({})
  const [plans, setPlans] = useState([])
  const [errorMessage, setErrorMessage] = useState('')
  const [currentPlanId, setCurrentPlanId] = useState('')
  const [currentPlan, setCurrentPlan] = useState('')
  const [currentPlanAmount, setCurrentPlanAmount] = useState('')

  const { id } = useParams()

  React.useEffect(() => {
    (async () => {
      const [memberData, memberCardData, plansData] = await Promise.all([
        client(`admin/member/${id}`),
        client(`admin/member/card/${id}`),
        client('admin/payment/plans')
      ])

      if (memberData.error) return setErrorMessage(member.message)

      setMember(memberData.member)
      setCompany(memberData.member.companyId.name)
      setDept(memberData.member.deptId.name)
      setPlans(plansData.plans.data.sort((a,b) => (b.amount < a.amount) ? 1 : -1))
      setCurrentPlanId(memberCardData.memberCard.planId)
      setCurrentPlanAmount(memberCardData.memberCard.amount / 100)

      if (memberCardData !== null) {
        plansData.plans.data.map(plan => {
          if (plan.id === memberCardData.memberCard.planId) {            
            setCurrentPlan(plan.nickname)
          }
        })
      }
    })()
  }, [])

  const submit = async e => {
    e.preventDefault();
    if (planId === '') return setErrorMessage('Please select a plan')
    if (planId === currentPlanId) return setErrorMessage('You selected the existing plan')

    setStatus('submitting');

    try {
      const data = await client('admin/payment/change-plan', { body: { memberId: id, planId, amount }} )
      if (data.error) {
        return setStatus('error')
      }

      setStatus('complete');
      // returned data
      // memberId
      // customerId - Stripe
      // cardId - Stripe
      // cardType
      // cardNumber - last 4 digits
      // expirationMonth
      // expirationYear
      // postalCode
    } catch (err) {
      setStatus('error');
    }
  };

  if (status === 'complete') {
    return <div className="PaymentForm-complete">Plan changed!</div>;
  }

  function handleChange(e) {
    const plan = e.target.value
    if (plan.indexOf('|') >= 0) {
      let planAry = plan.split('|')
      setPlanId(planAry[0])
      setAmount(planAry[1])
    }
  }

  return (
    <form className="PaymentForm" onSubmit={submit}>
      <div style={{ height: '25px' }}>{ errorMessage && errorMessage }</div>

      <div>Paying Manager: { member.firstName + ' ' + member.lastName}</div>
      <div>Company: { company }</div>
      <div>Dept: { dept }</div>
      <div>Current Plan: { currentPlan + ' $' + currentPlanAmount + '/mth' }</div><br/>
      <div>Changing a customer's plan will result in an immediate plan change and coorelating payment</div>
      <select onChange={handleChange}>
        <option value="">Please Select</option>
        {plans && plans.map(plan => {
          return <option key={plan.id} value={plan.id + '|' + plan.amount} selected={plan.id === currentPlanId}>{plan.nickname + ' - $' + Number(plan.amount) / 100 + '/mth'}</option>
        })}
      </select>
      <button type="submit" disabled={status === 'submitting'}>
        {status === 'submitting' ? 'Submitting' : 'Change Plan'}
      </button>
      {status === 'error' && <div className="PaymentForm-error">Something went wrong.</div>}
    </form>
  );
}

export default ChangePlan;
