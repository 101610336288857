export function formatDate(date) {
  const dateObj = new Date(date);
  return `${dateObj.getMonth() < 9 ? '0' + (dateObj.getMonth() + 1) : dateObj.getMonth() + 1}/${
    dateObj.getDate() < 9 ? '0' + (dateObj.getDate() + 1) : dateObj.getDate() + 1
  }/${dateObj.getFullYear()}`;
}

export function formatNumberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function formatNumberToDollarAmount(amount) {
  if (amount !== null && amount !== '' && !isNaN(amount)) return '$' + formatNumberWithCommas(Math.round(amount.toFixed(2)));
  return '-';
}

export const transactionTypes = [
  { name: 'Sales', value: 'SALE' },
  { name: 'Repairs', value: 'RO' },
  { name: 'Exchanges', valyue: 'EXCH' }
];

export const conditionCodes = [
  { name: 'NS (New Surplus)', value: 'NS' },
  { name: 'OH (Overhauled)', value: 'OH' },
  { name: 'SV (Serviceable)', value: 'SV' },
  { name: 'AR (As Removed)', value: 'AR' },
  { name: 'BER (Beyond Economic Repair)', value: 'BER' }
];

export const conditionCodeNames = [
  { name: 'New Surplus', value: 'NS' },
  { name: 'Overhauled', value: 'OH' },
  { name: 'Serviceable', value: 'SV' },
  { name: 'As Removed', value: 'AR' },
  { name: 'Beyond Economic Repair', value: 'BER' }
];

export const dateRanges = [
  { name: '1 month', value: 1 },
  { name: '3 months', value: 3 },
  { name: '6 months', value: 6 },
  { name: '12 months', value: 12 }
];

export const DEFAULT_TRANSACTION_TYPE = transactionTypes[1].value;
export const DEFAULT_CONDITION_CODE = conditionCodes[2].value;
export const DEFAULT_DATE_RANGE = dateRanges[3].value;
