import React from 'react';
import { AuthProvider } from './auth-context';
import { MemberProvider } from './member-context';

function AppProviders({ children }) {
  return (
    <AuthProvider>
      <MemberProvider>{children}</MemberProvider>
    </AuthProvider>
  );
}

export default AppProviders;
