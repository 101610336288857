import React from 'react';
import { useAuth } from './auth-context';

// ========== Context and Hooks ==========
const MemberContext = React.createContext();
function useMember() {
  const memberContext = React.useContext(MemberContext);
  if (memberContext === undefined) {
    // TODO: do we need a typeof
    throw new Error(`useMember must be used within a MemberProvider`);
  }
  return memberContext;
}

// ========== Context Provider ==========
function MemberProvider(props) {
  const { member } = useAuth();
  return <MemberContext.Provider value={member || null} {...props} />;
}

export { MemberProvider, useMember };
