import React from 'react';
import { Row, Col, Table, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Card, CardBody } from '../../components/Card';
import { useMember } from '../../context/member-context';
import ReactGA from 'react-ga';

const profileLinks = [
  { name: 'Change Profile', link: '/member/change-profile' },
  { name: 'Change Password', link: '/member/change-password' },
  { name: 'Manage Watchlist', link: '/member/manage-watchlist' }
];

function Profile() {
  const member = useMember();

  const company = typeof member.companyId === 'object' ? member.companyId : null;
  const department = typeof member.deptId === 'object' ? member.deptId : null;
  const departmentManager = typeof member.parentId === 'object' ? member.parentId : null;

  React.useEffect(() => {
    if (member) {
      ReactGA.initialize({
        trackingId: process.env.REACT_APP_GA_TRACKING_ID,
        title: 'Profile',
        pageview: window.location.pathname,
        gaOptions: {
          username: member.username
        }
      });
    }
  }, [member]);

  return (
    <Row>
      <Col md={{ span: 8, offset: 2 }} lg={{ span: 6, offset: 3 }}>
        <Card>
          <CardBody>
            <Row>
              <Col>
                <h3 className="text-center">{`${member.firstName} ${member.lastName}`}</h3>
                {/*<div className="text-center mb-3">
                  <Image src="https://via.placeholder.com/150" roundedCircle alt="Profile" />
                </div>*/}
              </Col>
            </Row>
            <Row>
              <Col>
                <Table size="sm" responsive>
                  <tbody>
                    <tr>
                      <th className="bg-active">First Name</th>
                      <td>{member.firstName}</td>
                    </tr>
                    <tr>
                      <th className="bg-active">Last Name</th>
                      <td>{member.lastName}</td>
                    </tr>
                    <tr>
                      <th className="bg-active">Email</th>
                      <td>{member.email}</td>
                    </tr>
                    <tr>
                      <th className="bg-active">Phone</th>
                      <td>{member.phone ? member.phone : '-'}</td>
                    </tr>
                    <tr>
                      <th className="bg-active">Registration Date</th>
                      <td>{member.registrationDate}</td>
                    </tr>
                    <tr>
                      <th className="bg-active">Company Name</th>
                      <td>{company ? company.name : '-'}</td>
                    </tr>
                    <tr>
                      <th className="bg-active">Department Name</th>
                      <td>{department ? department.name : '-'}</td>
                    </tr>
                    <tr>
                      <th className="bg-active">Department Manager</th>
                      <td>
                        {departmentManager ? (
                          <ul className="list-unstyled">
                            <li>{`${member.parentId.firstName} ${member.parentId.lastName}`}</li>
                            <li>
                              <a href={`mailto:${member.parentId.email}`}>{member.parentId.email}</a>
                            </li>
                          </ul>
                        ) : (
                          '-'
                        )}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
            </Row>
            <Row>
              <Col>
                <ul className="list-unstyled">
                  {profileLinks.map(({ name, link }, i) => (
                    <li key={name}>
                      <Link to={link}>{name}</Link>
                    </li>
                  ))}
                </ul>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
}

export default Profile;
