import React from 'react';
import { Row, Col, Table, Button, Form } from 'react-bootstrap';
import { Card, CardBody } from '../../components/Card';
import {
  getMemberWatchlists,
  getWatchlistDetails,
  createWatchlist,
  removePartFromWatchlist,
  deleteWatchlist
} from '../../api/watchlist-client';
import { useMember } from '../../context/member-context';
import ReactGA from 'react-ga';

function validateWatchlistName(watchlistName) {
  if (!watchlistName) return { error: true, message: `Watchlist name is required.` };
  if (watchlistName.length < 2) return { error: true, message: `Watchlist name must be two or more characters.` };
  return { error: false };
}

function CreateWatchList({ onCreate }) {
  const member = useMember();

  const [isLoading, setIsLoading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');

  const [watchlistName, setWatchlistName] = React.useState('');

  React.useEffect(() => {
    if (member) {
      ReactGA.initialize({
        trackingId: process.env.REACT_APP_GA_TRACKING_ID,
        title: 'Watchlist',
        pageview: window.location.pathname,
        gaOptions: {
          username: member.username
        }
      });
    }
  }, [member]);

  function handleSubmit(e) {
    e.preventDefault();
    setErrorMessage('');

    const { error: formError, message } = validateWatchlistName(watchlistName);
    if (formError) {
      ReactGA.event({
        category: 'Watchlist',
        action: 'Failed Creation Attempt',
        label: member.username
      });

      setErrorMessage(message);
    } else {
      ReactGA.event({
        category: 'Watchlist',
        action: 'Successfully Created',
        label: member.username
      });

      setIsLoading(true);
      createWatchlist(watchlistName).then(({ error, payload }) => {
        if (error) setErrorMessage(error);
        else onCreate(payload);
      });
    }
  }

  return (
    <>
      <Row>
        <Col md={{ offset: 2, span: 8 }}>
          <h3 className="text-center">Create a Watchlist</h3>
          <p className="text-center">
            Watchlists let you subscribe to parts and changes in their activity. Get started using the form below.
          </p>
        </Col>
      </Row>
      <Row>
        <Col md={{ offset: 3, span: 6 }}>
          <Form onSubmit={handleSubmit}>
            <Form.Group>
              <Form.Control
                type="text"
                value={watchlistName}
                onChange={e => setWatchlistName(e.target.value)}
                placeholder="Enter watchlist name"
              />
            </Form.Group>
            <Form.Group>{errorMessage && <Form.Text className="text-danger">{errorMessage}</Form.Text>}</Form.Group>
            <Form.Group className="text-center">
              <Button variant="primary" type="submit" disabled={isLoading}>
                Create Watchlist
              </Button>
            </Form.Group>
          </Form>
        </Col>
      </Row>
    </>
  );
}

function UpdateWatchList({ watchlists, onDelete }) {
  const member = useMember();
  const [isLoading, setIsLoading] = React.useState(true);
  const [error, setError] = React.useState(null);

  const [selectedWatchlistId, setSelectedWatchlistId] = React.useState(watchlists[0].id || '');
  const [selectedWatchlistDetails, setSelectedWatchlistDetails] = React.useState(null);

  React.useEffect(() => {
    setIsLoading(true);
    getWatchlistDetails(selectedWatchlistId).then(({ error, message, payload }) => {
      if (error) setError(message);
      else setSelectedWatchlistDetails(payload);
      setIsLoading(false);
    });
  }, [selectedWatchlistId]);

  function handleWatchlistSelect(e) {
    setSelectedWatchlistId(e.target.value);
  }

  function handleWatchlistDelete() {
    setIsLoading(true);
    deleteWatchlist(selectedWatchlistId).then(({ error, message }) => {
      if (error) {
        ReactGA.event({
          category: 'Watchlist',
          action: 'Failed Deletion Attempt',
          label: member.username
        });

        setError(message);
        setIsLoading(false);
      } else {
        ReactGA.event({
          category: 'Watchlist',
          action: 'Successfully Deleted',
          label: member.username
        });

        onDelete();
      }
    });
  }

  function handleRemovePart(partId) {
    setIsLoading(true);
    removePartFromWatchlist(selectedWatchlistId, partId).then(({ error, message, payload }) => {
      if (error) {
        ReactGA.event({
          category: 'Watchlist',
          action: 'Failed Remove Part',
          label: member.username
        });

        setError(message);
      } else {
        ReactGA.event({
          category: 'Watchlist',
          action: 'Successfully Removed Part',
          label: member.username
        });

        setSelectedWatchlistDetails(payload);
      }
      setIsLoading(false);
    });
  }

  return (
    <>
      <Row>
        <Col md={{ span: 5 }}>
          <Form.Group>
            <Form.Control as="select" value={selectedWatchlistId} onChange={handleWatchlistSelect} size="sm">
              {watchlists.map(({ name, id }) => (
                <option key={id} value={id}>
                  {name}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="pull-right">
            <Button variant="outline-danger" size="sm" disabled={isLoading} onClick={handleWatchlistDelete}>
              Delete Watchlist
            </Button>
          </Form.Group>
        </Col>
      </Row>
      {error && (
        <Row>
          <Col>
            <p className="text-danger">{error}</p>
          </Col>
        </Row>
      )}
      <Row>
        <Col>
          <div style={{ minHeight: 300 }}>
            <Table size="sm" responsive>
              <thead>
                <tr>
                  <th>Part Number</th>
                  <th>Description</th>
                  <th>Activity</th>
                  <th></th>
                </tr>
              </thead>
              {selectedWatchlistDetails ? (
                <tbody>
                  {selectedWatchlistDetails.parts.length === 0 ? (
                    <tr>
                      <td colSpan="4">
                        There are no parts in this watchlist. You can add parts by viewing a part and clicking the "Add
                        to Watchlist" Button.
                      </td>
                    </tr>
                  ) : (
                    <>
                      {selectedWatchlistDetails.parts.map(part => (
                        <tr key={part.partNumber}>
                          <td>{part.partNumber}</td>
                          <td>{part.description}</td>
                          <td>{part.activity}</td>
                          <td>
                            <Button variant="danger" size="xs" onClick={() => handleRemovePart(part.id)}>
                              Remove
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </>
                  )}
                </tbody>
              ) : (
                <tbody>
                  <tr>
                    <td colSpan="4">Please select a watchlist.</td>
                  </tr>
                </tbody>
              )}
            </Table>
          </div>
        </Col>
      </Row>
    </>
  );
}

function ManageWatchList() {
  const member = useMember();
  const [isLoading, setIsLoading] = React.useState(true);
  const [error, setError] = React.useState(null);
  const [memberWatchlists, setMemberWatchlists] = React.useState(null);

  React.useEffect(() => {
    let isCurrent = true;
    getMemberWatchlists()
      .then(({ error, message, payload }) => {
        if (!isCurrent) return;

        if (error) {
          setError(message);
        } else {
          setMemberWatchlists(payload);
        }
      })
      .finally(() => {
        if (!isCurrent) return;
        setIsLoading(false);
      });
    return () => {
      isCurrent = false;
    };
  }, []);

  function handleWatchlistCreated(createdWatchlist) {
    // Note: there is no point in fetching the latest list if the creation is a one off. We can "fake the list"
    // from the returned created watchlist
    setMemberWatchlists([{ id: createdWatchlist.id, name: createdWatchlist.name }]);
  }

  function handleWatchlistDeleted() {
    setMemberWatchlists(null);
  }

  return (
    <Row>
      <Col md={{ span: 10, offset: 1 }} lg={{ span: 8, offset: 2 }}>
        {isLoading ? (
          <div>Loading...</div>
        ) : (
          <Card>
            {error ? (
              <CardBody>{error}</CardBody>
            ) : (
              <CardBody>
                {memberWatchlists && memberWatchlists.length ? (
                  <UpdateWatchList onDelete={handleWatchlistDeleted} watchlists={memberWatchlists} />
                ) : (
                  <CreateWatchList onCreate={handleWatchlistCreated} />
                )}
              </CardBody>
            )}
          </Card>
        )}
      </Col>
    </Row>
  );
}

export default ManageWatchList;
