import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { client } from '../api/client';
import { useMember } from '../context/member-context';
import { Button, Container, Form, Row, Col, Table } from 'react-bootstrap';

function Upload() {
  const [files, setFiles] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [status, setStatus] = useState('');

  useEffect(() => {
    (async () => {
      const data = await client('files');
      if (data.error) return setErrorMessage(data.message);

      setFiles(data.files.sort((a, b) => (b.uploadDate < a.uploadDate ? -1 : 1)));
    })();
  }, []);

  async function upload(e) {
    setErrorMessage('');
    setStatus('We are uploading your file');
    const file = e.target.files[0];
    if (file === '') {
      setStatus('');
      return setErrorMessage('Please select a file');
    }
    if (file.size === 0) {
      setStatus('');
      return setErrorMessage('The selected file appears to be empty');
    }

    try {
      const data = await client('signed-url', { body: { fileName: file.name } });
      const response = await fetch(data.signedUrl, { method: 'PUT', body: file });
      if (!response.ok) {
        setStatus('');
        return setErrorMessage('There was an error processing your request');
      }

      const savedFile = await client('save-file', { body: { fileName: data.fileName, label: file.name } });
      if (savedFile.error) return setErrorMessage(savedFile.message);
      setStatus('File uploaded successfully');
      let updatedFiles = [...files];
      updatedFiles.push(savedFile.newFile);
      setFiles(updatedFiles.sort((a, b) => (b.uploadDate < a.uploadDate ? -1 : 1)));

      setTimeout(() => {
        setStatus('');
      }, 1000);
    } catch (err) {
      return setErrorMessage('There was an error processing your request');
    }
  }

  return (
    <Container fluid>
      <Row>
        <Col>
          <div>
            <br />
            <div style={{ height: '20px' }}>{errorMessage}</div>
            {status === '' && (
              <div style={{ height: '35px' }}>
                <div>Use the below button to upload a file.</div>
                <input type="file" onChange={e => upload(e)} />
              </div>
            )}
            {status !== '' && <div style={{ height: '35px' }}>{status}</div>}
            <br />
            <br />
            <br />
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div></div>
          <div></div>
          <div></div>
          {files && files.length !== 0 && (
            <table>
              <tbody>
                <tr>
                  <td>Name</td>
                  <td>Upload Date</td>
                  <td>Status</td>
                </tr>
                {files &&
                  files.map(file => {
                    return (
                      <tr key={file.id}>
                        <td>{file.label}&#160;&#160;&#160;</td>
                        <td>
                          {moment(file.uploadDate)
                            .format('DDMMMYYYY hhmm')
                            .toUpperCase()}
                          &#160;&#160;&#160;
                        </td>
                        <td>{file.status}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          )}
        </Col>
      </Row>
    </Container>
  );
}

export default Upload;
