import React, { useEffect, useState } from 'react';
import { client } from '../../api/client';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Table } from 'react-bootstrap';
import moment from 'moment';
import { useMember } from '../../context/member-context';

function AdminMemberSessions() {
  const member = useMember();
  const [members, setMembers] = useState([]);
  const [memberSessions, setMemberSessions] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    (async () => {
      if (member.role !== 'admin') return setErrorMessage('You are not authorized to access this page');

      const data = await client('admin/members/sessions');
      if (data.error) return setErrorMessage('The member you requested was not found');

      if (typeof data.members !== 'undefined' && typeof data.members !== 'undefined') {
        setMembers(data.members);
      }

      if (typeof data.membersSessions !== 'undefined' && typeof data.membersSessions !== 'undefined') {
        setMemberSessions(data.membersSessions);
      }
    })();
  }, []);

  if (member.role !== 'admin') return <div>{errorMessage}</div>;
  let memberAcct, location;

  return (
    <Container fluid>
      <Row>
        <Col>
          <h1>View Members</h1>
          <Link to="/admin">Admin</Link> > Member Sesions
          <br />
          <br />
        </Col>
      </Row>
      <Row>
        <Col>
          <Table>
            <tbody>
              {memberSessions &&
                memberSessions.map(session => {
                  memberAcct = members.filter(m => m.id === session.memberId)[0];
                  location = '';

                  if (session.city && session.stateOrProvince && session.postalCode && session.countryCode) {
                    location =
                      session.city +
                      ', ' +
                      session.stateOrProvince +
                      ' ' +
                      session.postalCode +
                      ' ' +
                      session.countryCode;
                  }

                  if (typeof session.logoutDate !== 'undefined') {
                    return (
                      <React.Fragment key={`activity_logout_${session.id}`}>
                        <tr>
                          <td>
                            <Link to={`/admin/member/${session.memberId}`}>
                              {memberAcct.firstName + ' ' + memberAcct.lastName}
                            </Link>
                          </td>
                          <td>
                            {moment(session.logoutDate)
                              .format('DDMMMYYYY')
                              .toUpperCase() +
                              ' ' +
                              moment(session.logoutDate).format('hhmm')}
                          </td>
                          <td>logout</td>
                          <td>{location}</td>
                        </tr>
                        <tr>
                          <td>
                            <Link to={`/admin/member/${session.memberId}`}>
                              {memberAcct.firstName + ' ' + memberAcct.lastName}
                            </Link>
                          </td>
                          <td>
                            {moment(session.loginDate)
                              .format('DDMMMYYYY')
                              .toUpperCase() +
                              ' ' +
                              moment(session.loginDate).format('hhmm')}
                          </td>
                          <td>login</td>
                          <td>{location}</td>
                        </tr>
                      </React.Fragment>
                    );
                  } else {
                    return (
                      <tr key={`activity_login_${session.id}`}>
                        <td>
                          <Link to={`/admin/member/${session.memberId}`}>
                            {memberAcct.firstName + ' ' + memberAcct.lastName}
                          </Link>
                        </td>
                        <td>
                          {moment(session.loginDate)
                            .format('DDMMMYYYY')
                            .toUpperCase() +
                            ' ' +
                            moment(session.loginDate).format('hhmm')}
                        </td>
                        <td>login</td>
                        <td>{location}</td>
                      </tr>
                    );
                  }
                })}
            </tbody>
          </Table>
        </Col>
      </Row>
    </Container>
  );
}

export default AdminMemberSessions;
