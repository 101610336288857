import React, { useState, useEffect } from 'react';
import { client } from '../../api/client'
import { displayRole, displayStatus } from '../../api/helper'
import { Link, useParams } from 'react-router-dom';
import { Container, Row, Col, Table } from 'react-bootstrap';
import moment from 'moment'
import { useMember } from '../../context/member-context'

function AdminCompanyMembers() {
  const member = useMember();
  const [members, setMembers] = useState([])
  const [company, setCompany] = useState('')
  const [errorMessage, setErrorMessage] = useState('');
  
  const { id } = useParams()

  useEffect(() => {
    (async () => {
      if (member.role !== 'admin') return setErrorMessage('You are not authorized to access this page')

      const [companyData, membersData] = await Promise.all([
        client(`admin/company/${id}`),
        client(`admin/company/members/${id}`)
      ])
      setCompany(companyData.company.name)
      setMembers(membersData.members)
    })()
  }, [])

  if (member.role !== 'admin') return <div>{errorMessage}</div>

  return(
    <Container fluid>
      <Row>
        <Col>
          <h1>{ company } Members</h1>
          <Link to='/admin'>Admin</Link> > <Link to='/admin/companies'>Companies</Link> > {company}<br/><br/>
        </Col>
      </Row>
      <Row>
        <Col>
          <Table>
            <tbody>
              <tr>
                <td>Name</td>
                <td>Email</td>
                <td>Phone</td>
                <td>Registration Date</td>
                <td>Role</td>
                <td>Status</td>
              </tr>
            {members && members.map((member) => {
              return (
                <tr key={`mem_${member.id}`}>
                    <td><Link to={`/admin/member/${member.id}`}>{ member.firstName + ' ' + member.lastName }</Link></td>
                    <td>{ member.email }</td>
                    <td>{ member.phone }</td>
                    <td>{ moment(member.registrationDate).format('DDMMMYYYY').toUpperCase() + ' ' + moment(member.registrationDate).format('hhmm') }</td>
                    <td>{ displayRole(member.role) }</td>
                    <td>{ displayStatus(member.active) }</td>
                </tr>
              )
            })}
            </tbody>
        </Table>
        </Col>
      </Row>
    </Container>
  )
}

export default AdminCompanyMembers;
