import React, { useEffect, useState } from 'react';
import { client } from '../../api/client';
import { Link, useParams } from 'react-router-dom';
import { Container, Form, Row, Col, Table } from 'react-bootstrap';
import { displayRole, displayStatus } from '../../api/helper';
import moment from 'moment';
import { useMember } from '../../context/member-context';

function AdminMember() {
  const member = useMember();

  const [companyId, setCompanyId] = useState('');
  const [company, setCompany] = useState('');
  const [dept, setDept] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [parentId, setParentId] = useState('');
  const [parent, setParent] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [role, setRole] = useState('');
  const [registrationDate, setRegistrationDate] = useState('');
  const [active, setActive] = useState('');
  const [memberSessions, setMemberSessions] = useState([]);
  const [memberCard, setMemberCard] = useState({});
  const [managesMembers, setManagesMembers] = useState([]);

  const [errorMessage, setErrorMessage] = useState('');
  const { id } = useParams();
  let activity, timeStamp, ipAddress;

  useEffect(() => {
    (async () => {
      if (member.role !== 'admin') return setErrorMessage('You are not authorized to access this page');

      const [memberData, memberCardData, membersData, sessionsData] = await Promise.all([
        client(`admin/member/${id}`),
        client(`admin/member/card/${id}`),
        client(`admin/manager/personnel/${id}`),
        client(`admin/member/sessions/${id}`)
      ]);

      if (memberData.error) return setErrorMessage('The member you requested was not found');

      if (typeof memberData.member.parentId !== 'undefined') {
        setParentId(memberData.member.parentId);
        setParent(memberData.member.parent);
      }

      setRole(memberData.member.role);
      setCompanyId(memberData.member.companyId.id);
      setCompany(memberData.member.company);
      setDept(memberData.member.dept);
      setEmail(memberData.member.email);
      setPhone(memberData.member.phone);
      setFirstName(memberData.member.firstName);
      setLastName(memberData.member.lastName);
      setRegistrationDate(memberData.member.registrationDate);
      setActive(memberData.member.active);

      if (typeof membersData !== 'undefined' && typeof membersData.members !== 'undefined') {
        setManagesMembers(membersData.members);
      }

      if (typeof sessionsData !== 'undefined' && typeof sessionsData.memberSessions !== 'undefined') {
        setMemberSessions(sessionsData.memberSessions);
      }

      if (typeof memberCardData !== 'undefined' && typeof memberCardData.memberCard !== 'undefined') {
        setMemberCard({
          id: memberCardData.memberCard.id,
          customerId: memberCardData.memberCard.customerId,
          subscriptionId: memberCardData.memberCard.subscriptionId,
          amount: memberCardData.memberCard.amount,
          cardId: memberCardData.memberCard.cardId,
          cardType: memberCardData.memberCard.cardType,
          cardNumber: memberCardData.memberCard.cardNumber,
          expirationMonth: memberCardData.memberCard.expirationMonth,
          expirationYear: memberCardData.memberCard.expirationYear,
          postalCode: memberCardData.memberCard.postalCode
        });
      }
    })();
  }, [id]);

  async function cancelSubscription() {
    const data = await client(`admin/payment/cancel-subscription`, { body: { memberId: id } });
    if (!data.error) {
      setMemberCard({});
    }
  }

  if (member.role !== 'admin') return <div>{errorMessage}</div>;
  let location

  return (
    <Container fluid>
      <Row>
        <Col>
          <h1>View Member</h1>
          <Link to="/admin">Admin</Link> > <Link to="/admin/companies">Companies</Link> >{' '}
          <Link to={`/admin/company-members/${companyId}`}>{company}</Link> > {firstName + ' ' + lastName}
          <br />
          <br />
        </Col>
      </Row>
      <Row>
        <Col>
          <div>Company: {company}</div>
          <div>Dept: {dept}</div>
          <div>Role: {displayRole(role)}</div>
          {parentId && (
            <div>
              <br />
              Manager: <Link to={`/admin/member/${parentId}`}>{parent}</Link>
              <br />
              <br />
            </div>
          )}

          {role === 'manager' && typeof memberCard.cardNumber === 'undefined' && (
            <div>
              <Link to={`/admin/payment/${id}`}>Add Payment</Link>
              <br />
              <br />
            </div>
          )}
          {role === 'manager' && typeof memberCard.cardNumber !== 'undefined' && (
            <div>
              Card on File
              <br />
              XXXX-{memberCard.cardNumber}
              <br />
              {memberCard.expirationMonth + '/' + memberCard.expirationYear}
              <br />
              {`$${Number(memberCard.amount / 100)}/mth`}
              <br />
              <Link to={`/admin/change-card/${id}`}>Change Card</Link>&#160;&#160;
              <Link to={`/admin/change-plan/${id}`}>Change Plan</Link>
              <br />
              <br />
              <span className="link" onClick={() => cancelSubscription()}>
                Cancel Plan
              </span>
              <br />
              <br />
            </div>
          )}
          <div>&#160;</div>
          <div>Email: {email}</div>

          {phone && <div>Phone: {phone}</div>}
          <div>
            Registration Date:{' '}
            {moment(registrationDate)
              .format('DDMMMYYYY')
              .toUpperCase() +
              ' ' +
              moment(registrationDate).format('hhmm')}
          </div>
          <br />
          <div>Status: {displayStatus(active)}</div>
        </Col>
        <Col>
          {memberSessions &&
            memberSessions.map(session => {
              location = ''
              if (session.city && session.stateOrProvince && session.postalCode && session.countryCode) {
                location = session.city + ', ' + session.stateOrProvince + ' ' + session.postalCode + ' ' + session.countryCode
              }
              
              if (typeof session.logoutDate !== 'undefined') {
                return (
                  <div key={`activity_logout_${session.id}`}>
                    <div>
                      {moment(session.logoutDate)
                        .format('DDMMMYYYY')
                        .toUpperCase() + ' ' +
                        moment(session.logoutDate).format('hhmm') +
                        ' ' +
                        'logout ' + location }
                    </div>
                    <div>
                      {moment(session.loginDate)
                        .format('DDMMMYYYY')
                        .toUpperCase() + ' ' +
                        moment(session.loginDate).format('hhmm') +
                        ' ' +
                        'login ' + location }
                    </div>
                  </div>
                );
              } else {
                return (<div key={`activity_login_${session.id}`}>
                    {moment(session.loginDate)
                      .format('DDMMMYYYY')
                      .toUpperCase() + ' ' +
                      moment(session.loginDate).format('hhmm') +
                      ' ' +
                      'login ' + location }
                  </div>)
              }              
            })}
        </Col>
      </Row>
      {managesMembers.length !== 0 && (
        <Row>
          <Col lg={3}>
            <br />
            Personnel Managed:
            <br />
            <Table responsive="sm">
              <tbody>
                {managesMembers.map(member => {
                  return (
                    <tr key={`mem_${member.id}`}>
                      <td>
                        <Link to={`/admin/member/${member.id}`}>{member.firstName + ' ' + member.lastName}</Link>
                      </td>
                      <td>{displayRole(member.role)}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Col>
        </Row>
      )}
    </Container>
  );
}

export default AdminMember;
