import React from 'react';
import { Form, Row, Col, Modal, Button } from 'react-bootstrap';
import ReactGA from 'react-ga';
import { useHistory } from 'react-router-dom';

import { getMemberWatchlists, createWatchlist, addPartToWatchlist } from '../../api/watchlist-client';
import { Card, CardTitle, CardBody } from '../../components/Card';
import { useMember } from '../../context/member-context';
import { client } from '../../api/client';

function validateWatchlistName(watchlistName) {
  if (!watchlistName) return { error: true, message: `Watchlist name is required.` };
  if (watchlistName.length < 2) return { error: true, message: `Watchlist name must be two or more characters.` };
  return { error: false };
}

function CreateWatchList({ onCreate }) {
  const [isLoading, setIsLoading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(null);

  const [watchlistName, setWatchlistName] = React.useState('');

  function handleSubmit(e) {
    e.preventDefault();
    setErrorMessage('');

    const { error: formError, message } = validateWatchlistName(watchlistName);
    if (formError) {
      setErrorMessage(message);
    } else {
      setIsLoading(true);
      createWatchlist(watchlistName).then(({ error, payload }) => {
        if (error) setErrorMessage(error);
        else onCreate(payload);
      });
    }
  }

  return (
    <>
      <Row>
        <Col md={{ offset: 2, span: 8 }}>
          <p className="text-center">
            Watchlists let you subscribe to parts and changes in their activity. Get started using the form below.
          </p>
        </Col>
      </Row>
      <Row>
        <Col md={{ offset: 3, span: 6 }}>
          <Form onSubmit={handleSubmit}>
            <Form.Group>
              <Form.Control
                type="text"
                value={watchlistName}
                onChange={e => setWatchlistName(e.target.value)}
                placeholder="Enter watchlist name"
              />
            </Form.Group>
            <Form.Group>{errorMessage && <Form.Text className="text-danger">{errorMessage}</Form.Text>}</Form.Group>
            <Form.Group className="text-center">
              <Button variant="primary" type="submit" disabled={isLoading}>
                Create Watchlist
              </Button>
            </Form.Group>
          </Form>
        </Col>
      </Row>
    </>
  );
}

function SelectWatchlist({ watchlists, partId, onAddPart }) {
  const member = useMember();

  const [isLoading, setIsLoading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const [selectedWatchlistId, setSelectedWatchlistId] = React.useState(watchlists[0] ? watchlists[0].id : '');

  function handleSubmit(e) {
    e.preventDefault();
    setErrorMessage('');
    setIsLoading(true);
    addPartToWatchlist(selectedWatchlistId, partId).then(({ error, message }) => {
      if (error) {
        ReactGA.event({
          category: 'Watchlist',
          action: 'Failed to Add ' + partId,
          label: member.username
        });

        setErrorMessage(message);
        setIsLoading(false);
      } else {
        ReactGA.event({
          category: 'Watchlist',
          action: 'Successfully Added ' + partId,
          label: member.username
        });

        onAddPart();
      }
    });
  }

  function handleWatchlistSelect(e) {
    setSelectedWatchlistId(e.target.value);
  }

  return (
    <Row>
      <Col md={{ offset: 3, span: 6 }}>
        <Form onSubmit={handleSubmit}>
          <Form.Group>
            <Form.Control as="select" value={selectedWatchlistId} onChange={handleWatchlistSelect}>
              {watchlists.map(({ name, id }) => (
                <option key={id} value={id}>
                  {name}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
          <Form.Group>{errorMessage && <Form.Text className="text-danger">{errorMessage}</Form.Text>}</Form.Group>
          <Form.Group className="text-center">
            <Button variant="primary" type="submit" disabled={isLoading || !selectedWatchlistId}>
              Select
            </Button>
          </Form.Group>
        </Form>
      </Col>
    </Row>
  );
}

function AddPartToWatchlistModal({ onHide, partId }) {
  const [isLoading, setIsLoading] = React.useState(true);
  const [errorMessage, setErrorMessage] = React.useState(null);
  const [memberWatchlists, setMemberWatchLists] = React.useState(null);

  React.useEffect(() => {
    let isCurrent = true;
    getMemberWatchlists()
      .then(({ error, message, payload }) => {
        if (!isCurrent) return;

        if (error) {
          setErrorMessage(message);
        } else {
          setMemberWatchLists(payload);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });

    return () => {
      isCurrent = false;
    };
  }, []);

  function handleWatchlistCreated(createdWatchlist) {
    // Note: there is no point in fetching the latest list if the creation is a one off. We can "fake the list"
    // from the returned created watchlist
    setMemberWatchLists([{ id: createdWatchlist.id, name: createdWatchlist.name }]);
  }

  return (
    <Modal onHide={onHide} show={true}>
      {isLoading ? (
        <div>Loading...</div>
      ) : (
        <>
          <Modal.Header closeButton>
            <Modal.Title>
              {memberWatchlists && memberWatchlists.length ? 'Select a watchlist' : 'Create a watchlist'}
            </Modal.Title>
          </Modal.Header>
          {errorMessage ? (
            <Modal.Body>{errorMessage}</Modal.Body>
          ) : (
            <Modal.Body>
              {memberWatchlists && memberWatchlists.length ? (
                <SelectWatchlist watchlists={memberWatchlists} partId={partId} onAddPart={() => onHide()} />
              ) : (
                <CreateWatchList onCreate={handleWatchlistCreated} />
              )}
            </Modal.Body>
          )}
        </>
      )}
    </Modal>
  );
}

// ========= Modules ========
function Overview({ data }) {
  const history = useHistory();
  const { error, message, payload: partDetails } = data;
  const [showAddToWatchlistModal, setShowAddToWatchlistModal] = React.useState(false);

  function handleAddToWatchListClose() {
    setShowAddToWatchlistModal(false);
  }

  function handleAddToWatchListClick() {
    setShowAddToWatchlistModal(true);
  }

  async function handleShareClick() {
    const returnedData = await client('part/share', {
      body: {
        partNumber: data.payload.partNumber,
        conditionCode: data.conditionCode,
        transactionType: data.transactionType,
        dateRange: data.dateRange
      }
    });

    history.push(`/shared/${returnedData.data.id}`);
  }

  if (error) {
    return (
      <Card className="m-b-md">
        <CardTitle>
          <h5>Part Overview</h5>
        </CardTitle>
        <CardBody>{message}</CardBody>
      </Card>
    );
  }

  return (
    <>
      <Card className="m-b-md">
        {showAddToWatchlistModal && (
          <AddPartToWatchlistModal onHide={handleAddToWatchListClose} partId={partDetails.id} />
        )}
        <CardTitle>
          <h5>Part Overview</h5>
        </CardTitle>
        <CardBody>
          <Row className="row">
            <Col sm={12} md={12} lg={3} className="mb-4">
              <img
                src={partDetails.img}
                className="img-fluid mx-auto rounded d-block"
                alt={`Part - ${partDetails.partNumber}`}
              />
            </Col>
            <Col sm={6} md={6} lg={4}>
              <table className="table table-bordered">
                <tbody>
                  <tr>
                    <th className="bg-active">Part Number</th>
                    <td className="text-3xl font-bold">{partDetails.partNumber}</td>
                  </tr>
                  <tr>
                    <th className="bg-active">Description</th>
                    <td>{partDetails.description || 'No Data Available'}</td>
                  </tr>
                  <tr>
                    <th className="bg-active">MFR</th>
                    <td>{partDetails.mfr || 'No Data Available'}</td>
                  </tr>
                  <tr>
                    <th className="bg-active">ATA Chapter</th>
                    <td>{partDetails.ataChapter || 'No Data Available'}</td>
                  </tr>
                  <tr>
                    <th className="bg-active">Alternates</th>
                    <td>
                      {!partDetails.alternates || partDetails.alternates.length === 0 ? (
                        <span>No Data Available</span>
                      ) : (
                        <ul className="list-unstyled list-columns-2">
                          {partDetails.alternates
                            .sort((a, b) => a - b)
                            .map(mod => (
                              <li key={mod} style={{ padding: 2 }}>
                                {mod}
                              </li>
                            ))}
                        </ul>
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
              {!partDetails.shared && (
                <div>
                  <button className="btn btn-primary btn-xs mr-1" onClick={handleAddToWatchListClick}>
                    <i className="fa fa-eye mr-1"></i>Add To Watchlist
                  </button>
                  <button className="btn btn-success btn-xs" onClick={handleShareClick}>
                    <i className="fa fa-share-alt mr-1"></i>Share
                  </button>
                </div>
              )}
            </Col>
            <Col sm={6} md={6} lg={4}>
              <table className="table table-bordered table-condensed">
                <tbody>
                  <tr>
                    <th className="bg-active">TAT</th>
                    <td>
                      <ul className="list-unstyled">
                        <li style={{ padding: 2 }}>Test - {partDetails.repairCycles.test || 'N/A'}</li>
                        <li style={{ padding: 2 }}>Repair - {partDetails.repairCycles.repair || 'N/A'}</li>
                        <li style={{ padding: 2 }}>Overhaul - {partDetails.repairCycles.overhaul || 'N/A'}</li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <th className="bg-active">Aircraft Types</th>
                    <td>
                      {partDetails.aircraftTypes.length === 0 ? (
                        <span>No Data Available</span>
                      ) : (
                        <ul className="list-unstyled list-columns-2">
                          {partDetails.aircraftTypes
                            .sort((a, b) => a - b)
                            .map(aircraftType => (
                              <li key={aircraftType} style={{ padding: 2 }}>
                                {aircraftType}
                              </li>
                            ))}
                        </ul>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th className="bg-active">Service Bulletin</th>
                    <td>
                      {partDetails.latestServiceBulliten.name && partDetails.latestServiceBulliten.link ? (
                        <a href={partDetails.latestServiceBulliten.link}>{partDetails.latestServiceBulliten.name}</a>
                      ) : (
                        'No Data Available'
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  );
}

export default Overview;
