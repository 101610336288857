import React, { useEffect, useState } from 'react';
import { client } from '../../api/client';
import { Link } from 'react-router-dom';
import { Button, Container, Modal, Row, Col, Table } from 'react-bootstrap';
import moment from 'moment';
import { useMember } from '../../context/member-context';
import ReactGA from 'react-ga';

function ManageMembers() {
  const member = useMember();

  const [members, setMembers] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [showDisableMemberModal, setShowDisableMemberModal] = useState(false);
  const [showEnableMemberModal, setShowEnableMemberModal] = useState(false);
  const [modifyMember, setModifyMember] = useState({});

  useEffect(() => {
    if (member) {
      ReactGA.initialize({
        trackingId: process.env.REACT_APP_GA_TRACKING_ID,
        title: 'Managed Members',
        pageview: window.location.pathname,
        gaOptions: {
          username: member.username
        }
      });
    }
  }, [member]);

  function handleDisableMemberClick(member) {
    setErrorMessage('');
    setModifyMember(member);
    setShowDisableMemberModal(true);
  }

  function handleDisableMemberClose() {
    setModifyMember({});
    setShowDisableMemberModal(false);
  }

  function handleEnableMemberClick(member) {
    setErrorMessage('');
    setModifyMember(member);
    setShowEnableMemberModal(true);
  }

  function handleEnableMemberClose() {
    setShowEnableMemberModal(false);
  }

  useEffect(() => {
    (async () => {
      if (member.role !== 'manager') return setErrorMessage('You are not authorized to access this page');
      const data = await client('manager/members');
      if (data.error) return setErrorMessage(data.message);

      setMembers(data.members);
    })();
  }, []);

  function displayActions(member) {
    if (member.active)
      return (
        <span className="link" onClick={e => handleDisableMemberClick(member)}>
          Disable
        </span>
      );
    else
      return (
        <span className="link" onClick={e => handleEnableMemberClick(member)}>
          Enable
        </span>
      );
  }

  async function disableMember(memberId) {
    setErrorMessage('');
    const data = await client('manager/disable', { body: { memberId } });
    if (data.error) {
      return setErrorMessage(data.message);
    }

    let memberList = [];
    members.map(member => {
      if (member.id === memberId) {
        member.active = false;
      }

      memberList.push(member);
    });

    setShowDisableMemberModal(false);
    setMembers([]);
    setMembers(memberList);
  }

  async function enableMember(memberId) {
    setErrorMessage('');
    const data = await client('manager/enable', { body: { memberId } });
    if (data.error) {
      return setErrorMessage(data.message);
    }

    let memberList = [];
    members.map(member => {
      if (member.id === memberId) {
        member.active = true;
      }

      memberList.push(member);
    });

    setShowEnableMemberModal(false);
    setMembers([]);
    setMembers(memberList);
  }

  function displayAccountStatus(member) {
    if (member.active) return 'Active';
    else if (!member.active) return 'Disabled';
    else return 'Not Created';
  }

  if (member.role !== 'manager') return <div>{errorMessage}</div>;

  return (
    <>
      <Modal onHide={handleDisableMemberClose} show={showDisableMemberModal}>
        <>
          <Modal.Header closeButton>
            <Modal.Title>Disable {modifyMember.firstName + ' ' + modifyMember.lastName}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {errorMessage ? (
              <>
                {errorMessage}
                <br />
                <Button onClick={() => handleDisableMemberClose()}>Close</Button>
              </>
            ) : (
              <>
                <Button variant="secondary" onClick={() => disableMember(modifyMember.id)}>
                  Yes
                </Button>
                &#160;&#160;&#160;
                <Button onClick={() => handleDisableMemberClose()}>No</Button>
              </>
            )}
          </Modal.Body>
        </>
      </Modal>

      <Modal onHide={handleEnableMemberClose} show={showEnableMemberModal}>
        <>
          <Modal.Header closeButton>
            <Modal.Title>Enable {modifyMember.firstName + ' ' + modifyMember.lastName}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Button variant="secondary" onClick={() => enableMember(modifyMember.id)}>
              Yes
            </Button>
            &#160;&#160;&#160;
            <Button onClick={() => handleEnableMemberClose()}>No</Button>
          </Modal.Body>
        </>
      </Modal>
      <Container fluid>
        <Row>
          <Col>
            <Table>
              <tbody>
                {members && members.length !== 0 && (
                  <tr>
                    <td>Email</td>
                    <td>Name</td>
                    <td>Registration Date</td>
                    <td>Actions</td>
                  </tr>
                )}
                {members &&
                  members.map(member => {
                    return (
                      <tr key={member.id}>
                        <td>
                          <Link to={`/manager/member/${member.id}`}>{member.email}</Link>
                        </td>
                        <td>{member.firstName + ' ' + member.lastName}</td>
                        <td>
                          {moment(member.registrationDate)
                            .format('DDMMMYYYY')
                            .toUpperCase()}
                        </td>
                        <td>{displayAccountStatus(member)}</td>
                        <td>{displayActions(member)}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default ManageMembers;
