import React, { useState } from 'react';
import { CardElement, injectStripe } from 'react-stripe-elements';
import './PaymentForm.css';
import { client } from '../../api/client'
import { Link, useParams } from 'react-router-dom';

function PaymentForm({ stripe }) {
  const [status, setStatus] = useState('default');
  const [planId, setPlanId] = useState('')
  const [amount, setAmount] = useState('')
  const [company, setCompany] = useState('')
  const [dept, setDept] = useState('')
  const [member, setMember] = useState({})
  const [plans, setPlans] = useState([])
  const [errorMessage, setErrorMessage] = useState('')

  const { id } = useParams()

  React.useEffect(() => {
    (async () => {
      const [memberData, plansData] = await Promise.all([
        client(`admin/member/${id}`),
        client('admin/payment/plans')
      ])

      if (memberData.error) return setErrorMessage(member.message)    
      setMember(memberData.member)
      setCompany(memberData.member.company)
      setDept(memberData.member.dept)
      setPlans(plansData.plans.data.sort((a,b) => (b.amount < a.amount) ? 1 : -1))
    })()
  }, [])

  const submit = async e => {
    e.preventDefault();
    if (planId === '') return setErrorMessage('Please select a plan')

    setStatus('submitting');

    try {
      const { token } = await stripe.createToken({ name: 'Name' });

      const data = await client('payment', { body: { token: token.id, memberId: id, planId, amount }} )
      if (data.error) {
        setStatus('error')
      }

      setStatus('complete');
      // returned data
      // memberId
      // customerId - Stripe
      // cardId - Stripe
      // cardType
      // cardNumber - last 4 digits
      // expirationMonth
      // expirationYear
      // postalCode
    } catch (err) {
      setStatus('error');
    }
  };

  if (status === 'complete') {
    return <div className="PaymentForm-complete">Payment successful!</div>;
  }

  function handleChange(e) {
    const plan = e.target.value
    if (plan.indexOf('|') >= 0) {
      let planAry = plan.split('|')
      setPlanId(planAry[0])
      setAmount(planAry[1])
    }
  }

  return (
    <form className="PaymentForm" onSubmit={submit}>
      <div style={{ height: '25px' }}>{ errorMessage && errorMessage }</div>

      <div>Paying Manager: { member.firstName + ' ' + member.lastName}</div>
      <div>Company: { company }</div>
      <div>Dept: { dept }</div>
      <select onChange={handleChange}>
        <option value="">Please Select</option>
        {plans && plans.map(plan => {
          return <option key={`plan_${plan.id}`} value={plan.id + '|' + plan.amount}>{`${plan.nickname + ' - $' + Number(plan.amount) / 100 }/mth`}</option>
        })}
      </select>

      <CardElement />
      <button className="PaymentForm-button" type="submit" disabled={status === 'submitting'}>
        {status === 'submitting' ? 'Submitting' : 'Submit Order'}
      </button>
      {status === 'error' && <div className="PaymentForm-error">Something went wrong.</div>}
    </form>
  );
}

export default injectStripe(PaymentForm);
