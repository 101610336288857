import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import $ from 'jquery';
import { smoothlyMenu } from '../theme/helpers/helpers';
import { Link } from 'react-router-dom';
import { useMember } from '../context/member-context';
import { useAuth } from '../context/auth-context';
import { client } from '../api/client';
import Autocomplete from 'react-autocomplete'

function TopHeader() {
  const { logout } = useAuth();
  const member = useMember();
  const history = useHistory();

  const allParts = [
    { partNumber: '3289562-5'},
    { partNumber: '14330-375'},
    { partNumber: '790425A6'},
    { partNumber: '28B545-9'},
    { partNumber: '441921-5'},
    { partNumber: '7121-19971-01AC'},
    { partNumber: '773289-15'},
    { partNumber: '4101002-11'},
    { partNumber: '20790-03AC'},
    { partNumber: '6764B040000'}
  ]

  const [searchPart, setSearchPart] = useState('')
  const [parts, setParts] = useState(allParts);
  
  function toggleSideNavigation(e) {
    e.preventDefault();
    $('body').toggleClass('mini-navbar'); // eslint-disable-line
    smoothlyMenu();
  }

  function handleLogout() {
    logout();
  }

  function navigateToPart(e) {
    setSearchPart(e)
    setParts(allParts)
    setTimeout(() => {
      setSearchPart('')
    }, 500)

    history.push(`/part/${e}`)
  }

  async function handleSearch(input) {
    setSearchPart(input)
    if (input.length < 2) {
      setParts(allParts)
      return
    }

    const data = await client('search', { body: { part: input } });
    setParts([])
    if (data.error) return
    if (data.parts.length !== 0)
    setParts(data.parts)
  }
  
  return (
    <div className="row border-bottom">
      <nav className="navbar navbar-static-top white-bg" role="navigation" style={{ marginBottom: 0 }}>
        <div className="navbar-header">
          <button
            className="navbar-minimalize minimalize-styl-2 btn btn-primary"
            onClick={e => toggleSideNavigation(e)}
          >
            <i className="fa fa-bars"></i>
          </button>
          <form role="search" className="navbar-form-custom" method="post" action="#">
            <div className="form-group search-container">
              <Autocomplete
                inputProps={{
                  placeholder:"Parts Search",
                  style: {
                    width: '225px',
                    height: '32px',
                    fontSize: '14px',
                    border: 'none'
                  }
                }}
                className="form-control"
                menuStyle={{
                  borderRadius: '3px',
                  boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
                  background: 'rgba(255, 255, 255, 0.9)',
                  padding: '1px 0',
                  width: '225px',
                  top: '0',
                  left: '0',
                  zIndex: 9999,
                  fontSize: '110%',
                  position: 'relative',
                  overflow: 'auto',
                  maxHeight: '125px'
                }}
                getItemValue={(part) => part.partNumber}
                items={parts}
                renderItem={(part, isHighlighted) =>
                  <div key={part.partNumber} style={{ cursor: 'pointer', background: isHighlighted ? 'lightgray' : 'white' }}>
                    {part.partNumber}
                  </div>
                }
                value={searchPart}
                onChange={(e) => handleSearch(e.target.value)}
                onSelect={(e) => navigateToPart(e)}
              />
            </div>
          </form>
        </div>
        <ul className="nav navbar-top-links navbar-right">
          <li>
            <div className="dropdown">
              {/* TODO: Make dropdown dynamic with state hide/show */}
              <button data-toggle="dropdown" className="dropdown-toggle btn">
                <span className="clear">
                  <span className="text-muted block">
                    {`${member.firstName} ${member.lastName}`} <b className="caret"></b>
                  </span>
                </span>
              </button>
              <ul className="dropdown-menu animated m-t-xs">
                <li>
                  <Link className="dropdown-item" to="/member">
                    Profile
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item" to="/member/change-password">
                    Change Password
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item" to="/upload">
                    Upload File
                  </Link>
                </li>
                {member.role === 'manager' ? (
                  <>
                    <li>
                      <Link className="dropdown-item" to="/manager/members">
                        Manage Members
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/manager/licenses">
                        Invite
                      </Link>
                    </li>
                  </>
                ) : (
                  ''
                )}
                <li className="dropdown-divider"></li>
                <li>
                  <Link className="dropdown-item" to="/logout" onClick={handleLogout}>
                    Logout
                  </Link>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </nav>
    </div>
  );
}

export default TopHeader;
