import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { client } from '../api/client';
import { useAuth } from '../context/auth-context';
import { Button, Container, Form, Row, Col, Table } from 'react-bootstrap';
import ReactGA from 'react-ga';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function Invitee() {
  const { licenseRegister } = useAuth();
  const query = useQuery();
  const id = query.get('id')
  const key = query.get('key')

  const [errorMessage, setErrorMessage] = useState('');
  const [validationError, setValidationError] = useState(false);
  const [inviterFirstName, setInviterFirstName] = useState('');
  const [inviterLastName, setInviterLastName] = useState('');
  const [inviterPhone, setInviterPhone] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [password, setPassword] = useState('');
  const [verifyPassword, setVerifyPassword] = useState('');
  const [phone, setPhone] = useState('');

  useEffect(() => {
    ReactGA.initialize({
      trackingId: process.env.REACT_APP_GA_TRACKING_ID,
      title: 'Register',
      pageview: window.location.pathname
    });
  }, []);

  useEffect(() => {
    (async () => {
    
      if (id === null || key === null) {
        return setErrorMessage('id and/or key not specified');
      }

      const data = await client(`invitee`, { body: { id, key } });
      if (data.error) {
        setValidationError(true);
        return setErrorMessage(data.message);
      }
      setInviterFirstName(data.firstName);
      setInviterLastName(data.lastName);
      setInviterPhone(data.phone);
    })();
  }, []);

  async function handleSubmit(e) {
    e.preventDefault();
    setErrorMessage('');

    if (firstName === '') return setErrorMessage('Please enter your first name');
    if (lastName === '') return setErrorMessage('Please enter your last name');
    if (password === '') return setErrorMessage('Please enter a password');
    if (password.length < 6) return setErrorMessage('Please enter a valid password');
    if (verifyPassword === '') return setErrorMessage('Please verify your password');

    licenseRegister({ id, key, firstName, lastName, password }).then(({ error, message }) => {
      if (error) {
        ReactGA.event({
          category: 'Register',
          action: 'Failed Attempt'
        });

        return setErrorMessage(message);
      } else {
        ReactGA.event({
          category: 'Register',
          action: 'Successfully Registered'
        });
      }
    });
  }

  if (id === null || key === null) {
    return <div>id and/or key not specified</div>
  }

  return (
    <div>
      <Form onSubmit={handleSubmit} className="mx-5">
        <Container>
          <Row style={{ height: '25px' }}>
            <Col>
              {inviterPhone &&
                inviterFirstName &&
                `${inviterFirstName} ${inviterLastName} (${inviterPhone}) invited you to join Jet Data.`}
              {!inviterPhone &&
                inviterFirstName &&
                `${inviterFirstName} ${inviterLastName} invited you to join Jet Data.`}
            </Col>
          </Row>
          <Row style={{ height: '25px' }}>
            <Col>
              <Form.Group>{errorMessage && <Form.Text className="text-danger">{errorMessage}</Form.Text>}</Form.Group>
            </Col>
          </Row>
          {!validationError && (
            <>
              <Row>
                <Col lg={3}>
                  <Form.Group>
                    <Form.Label>First Name</Form.Label>
                    <Form.Control
                      type="text"
                      autoComplete="off"
                      value={firstName}
                      onChange={e => setFirstName(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col lg={3}>
                  <Form.Group>
                    <Form.Label>Last Name</Form.Label>
                    <Form.Control
                      type="text"
                      autoComplete="off"
                      value={lastName}
                      onChange={e => setLastName(e.target.value)}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg={3}>
                  <Form.Group>
                    <Form.Label>Password (min 6 chars)</Form.Label>
                    <Form.Control
                      type="password"
                      autoComplete="off"
                      placeholder=""
                      value={password}
                      onChange={e => setPassword(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col lg={3}>
                  <Form.Group>
                    <Form.Label>Verify Password</Form.Label>
                    <Form.Control
                      type="password"
                      autoComplete="off"
                      placeholder=""
                      value={verifyPassword}
                      onChange={e => setVerifyPassword(e.target.value)}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg={3}>
                  <Form.Group>
                    <Form.Label>Phone (optional)</Form.Label>
                    <Form.Control
                      type="text"
                      autoComplete="off"
                      placeholder=""
                      value={phone}
                      onChange={e => setPhone(e.target.value)}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Button variant="primary" type="submit">
                    Register
                  </Button>
                </Col>
              </Row>
            </>
          )}
        </Container>
      </Form>
    </div>
  );
}

export default Invitee;
