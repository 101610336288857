import React, { useState, useEffect } from 'react';
import { client } from '../../api/client';
import { Link } from 'react-router-dom';
import { Button, Container, Form, Row, Col, Table, useAccordionToggle } from 'react-bootstrap';
import { useMember } from '../../context/member-context';

function AdminCompanies() {
  const member = useMember();

  const { createCompany } = useAccordionToggle;
  const [companies, setCompanies] = useState([]);
  const [company, setCompany] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    (async () => {
      if (member.role !== 'admin') return setErrorMessage('You are not authorized to access this page');

      const data = await client('admin/companies-depts');
      if (data.error) return setErrorMessage(data.errorMessage);

      setCompanies(data.companies);
    })();
  }, []);

  async function handleSubmit(e) {
    e.preventDefault();
    setErrorMessage('');

    if (company === '') return setErrorMessage('Please enter a company');
    const data = await client('admin/create-company', { body: { company } });
    if (data.error) return setErrorMessage(data.message);

    let existingCompanies = companies;
    setCompanies([]);
    existingCompanies.push({
      id: data.company.id,
      name: data.company.name,
      depts: 0,
      licenseLimit: 0
    });
    setCompanies(existingCompanies);
    setCompany('');
  }

  if (member.role !== 'admin') return <div>{errorMessage}</div>;

  return (
    <div>
      <Form onSubmit={handleSubmit}>
        <Container fluid>
          <Row>
            <Col>
              <h1>Manage Companies</h1>
              <Link to="/admin">Admin</Link> > Companies
            </Col>
          </Row>
          <Row style={{ height: '25px' }}>
            <Col>
              <Form.Group>{errorMessage && <Form.Text className="text-danger">{errorMessage}</Form.Text>}</Form.Group>
            </Col>
          </Row>
          <Row>
            <Col lg={3}>
              <Form.Group>
                <Form.Control
                  type="text"
                  placeholder="Enter company"
                  value={company}
                  onChange={e => setCompany(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col>
              <Button variant="primary" type="submit">
                Create Company
              </Button>
            </Col>
          </Row>
          <Row>
            <Col>
              <Table>
                <tbody>
                  <tr>
                    <td>Company</td>
                    <td>Depts</td>
                    <td>Members</td>
                    <td>Licenses</td>
                  </tr>
                  {companies &&
                    companies.map(company => {
                      return (
                        <tr key={`co_${company.id}`}>
                          <td>
                            <Link to={`/admin/company/${company.id}`}>{company.name}</Link>
                          </td>
                          <td><Link to={`/admin/company/${company.id}`}>{company.depts !== 1 ? `${company.depts} depts` : `${company.depts} dept`}</Link></td>
                          <td>
                            {company.licenseLimit !== 0 ? (
                              <Link to={`/admin/company-members/${company.id}`}>{company.members} members</Link>
                            ) : (
                              <span>0 members</span>
                            )}
                          </td>
                          <td>{company.licenseLimit} license limit</td>
                        </tr>
                      );
                    }
                  )}
                </tbody>
              </Table>
            </Col>
          </Row>
        </Container>
      </Form>
    </div>
  );
}

export default AdminCompanies;
