import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { client } from '../../api/client';
import { displayRole, displayStatus } from '../../api/helper';
import { Link, useParams } from 'react-router-dom';
import { Button, Container, Row, Col, Table, Form, Modal } from 'react-bootstrap';
import { useMember } from '../../context/member-context';

function AdminCompanyMembers() {
  const member = useMember();

  const [members, setMembers] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [showDisableMemberModal, setShowDisableMemberModal] = useState(false)
  const [showEnableMemberModal, setShowEnableMemberModal] = useState(false)
  const [modifyMember, setModifyMember] = useState({})

  function handleDisableMemberClick(member) {
    setErrorMessage('');
    setModifyMember(member)
    setShowDisableMemberModal(true)
  }

  function handleDisableMemberClose() {
    setModifyMember({})
    setShowDisableMemberModal(false)
  }
  
  function handleEnableMemberClick(member) {
    setErrorMessage('');
    setModifyMember(member)
    setShowEnableMemberModal(true)
  }

  function handleEnableMemberClose() {
    setShowEnableMemberModal(false)
  }

  let { id } = useParams();
  if (typeof id === 'undefined') id = '';

  useEffect(() => {
    (async () => {
      if (member.role !== 'admin') return setErrorMessage('You are not authorized to access this page');

      let data = await client(`admin/members/${id}`);
      setMembers(data.members);
    })();
  }, []);
  
  async function disableMember(memberId) {
    setErrorMessage('');
    const data = await client(`admin/member/disable`, { body: { memberId } });
    if (data.error) {
      return setErrorMessage(data.message);
    }

    let memberList = [];
    members.map(member => {
      if (member.id === memberId) {
        member.active = false;
      }

      memberList.push(member);
    });

    setShowDisableMemberModal(false)
    setMembers([]);
    setMembers(memberList);
  }

  async function enableMember(memberId) {
    setErrorMessage('');
    const data = await client(`admin/member/enable`, { body: { memberId } });
    if (data.error) {
      return setErrorMessage(data.message);
    }

    let memberList = [];
    members.map(member => {
      if (member.id === memberId) {
        member.active = true;
      }

      memberList.push(member);
    });

    setShowEnableMemberModal(false)
    setMembers([]);
    setMembers(memberList);
  }

  function displayActions(member) {
    if (member.active)
      return (
        <span className="link" onClick={e => handleDisableMemberClick(member)}>
          Disable
        </span>
      );
    else
      return (
        <span className="link" onClick={e => handleEnableMemberClick(member)}>
          Enable
        </span>
      );
  }

  if (member.role !== 'admin') return <div>{errorMessage}</div>;

  return (
    <>
      <Modal onHide={handleDisableMemberClose} show={showDisableMemberModal}>
          <>
            <Modal.Header closeButton>
              <Modal.Title>
                Disable {modifyMember.firstName + ' ' + modifyMember.lastName}  
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            {errorMessage ? (
              <>
                {errorMessage}<br/>
                <Button onClick={() => handleDisableMemberClose()}>Close</Button>
              </>
            ) : (
              <>
                <Button variant="secondary" onClick={() => disableMember(modifyMember.id)}>Yes</Button>&#160;&#160;&#160;
                <Button onClick={() => handleDisableMemberClose()}>No</Button>
              </>
            )}
            </Modal.Body>
          </>
      </Modal>

      <Modal onHide={handleEnableMemberClose} show={showEnableMemberModal}>
          <>
            <Modal.Header closeButton>
              <Modal.Title>
                Enable {modifyMember.firstName + ' ' + modifyMember.lastName}  
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Button onClick={() => enableMember(modifyMember.id)}>Yes</Button>&#160;&#160;&#160;
              <Button onClick={() => handleEnableMemberClose()}>No</Button>
            </Modal.Body>
          </>
      </Modal>

      <Container fluid>
        <Row>
          <Col>
            <h1>Manage Members</h1>
            <Link to="/admin">Admin</Link> > All Members
            <br />
            <br />
            <Link to="/admin/create-member/">Create Member</Link>
            <br />
          </Col>
        </Row>
        <Row>
          <Col>
            <Table>
              <tbody>
                {members &&
                  members.map(member => {
                    return (
                      <tr key={`mem_${member.id}`}>
                        <td>
                          <Link to={`/admin/member/${member.id}`}>{member.firstName + ' ' + member.lastName}</Link>
                        </td>
                        <td>{member.email}</td>
                        <td>{member.phone}</td>
                        <td>{member.company || member.dept ? member.company + ' - ' + member.dept : 'n/a'}</td>
                        <td>
                          {moment(member.registrationDate)
                            .format('DDMMMYYYY')
                            .toUpperCase() +
                            ' ' +
                            moment(member.registrationDate).format('hhmm')}
                        </td>
                        <td>{displayRole(member.role)}</td>
                        <td>{displayStatus(member.active)}</td>
                        <td>{member.cardNumber ? `XXXX-${member.cardNumber}` : ''}</td>
                        <td>{displayActions(member)}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default AdminCompanyMembers;
