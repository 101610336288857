import React from 'react';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga';
import Logo from '../theme/assets/img/brand/jetdata-logo-text.png';

function ErrorPage() {
  React.useEffect(() => {
	ReactGA.initialize({
	trackingId: process.env.REACT_APP_GA_TRACKING_ID,
	title: 'Error Page',
	pageview: window.location.pathname
	});
  }, []);

  return (
    <section className="m-t-md pb-4">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg">
		  	<div className="text-center" style={{ paddingTop: '25px' }}>
				<img src={Logo} className="img-fluid" alt="Jet Data logo" /><br/><br/>
				The page you requested wasn't found.<br/><br/>
				<Link to="/">Click here</Link> to go home
			</div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ErrorPage;
