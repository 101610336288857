import { client } from './client';

function changePassword({ password, newPassword, verifyPassword }) {
  return client('member/change-password', { body: { password, newPassword, verifyPassword } });
}

function changeProfile({ firstName, lastName, phone, email }) {
  return client('member/change-profile', { body: { firstName, lastName, phone, email } });
}

export { changePassword, changeProfile };
