import React from 'react';

export function Spinner() {
  return (
    <div style={{ minHeight: '500px', display: 'flex', alignItems: 'center' }}>
      <div className="sk-spinner sk-spinner-wandering-cubes">
        <div className="sk-cube1"></div>
        <div className="sk-cube2"></div>
      </div>
    </div>
  );
}
