import React from 'react';
import * as authClient from '../api/auth-client.js';

// ========== Context and Hooks ==========
const AuthContext = React.createContext();
function useAuth() {
  const authContext = React.useContext(AuthContext);
  if (authContext === undefined) {
    throw new Error('useAuth must be used within AuthProvider');
  }
  return authContext;
}

// ========== Context Providers ==========
function AuthProvider(props) {
  const [firstLoadDone, setFirstLoadDone] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);
  const [error, setError] = React.useState(null);
  const [data, setData] = React.useState(null);

  React.useEffect(() => {
    let isCurrentRequest = true; // TODO: Is this a necessary optimization?
    setIsLoading(true);
    authClient.initAppData().then(({ error, message, payload }) => {
      if (!isCurrentRequest) return;
      if (error) {
        setError(message);
      } else {
        setData({ member: payload });
      }
      setIsLoading(false);
    });

    return () => {
      isCurrentRequest = false;
    };
  }, []);

  React.useLayoutEffect(() => {
    if (!isLoading) {
      setFirstLoadDone(true);
    }
  }, [isLoading]);

  if (!firstLoadDone) {
    if (isLoading) {
      return (
        <div className="d-flex justify-content-center align-items-center flex-column">
          <h1>Loading...</h1>
        </div>
      );
      // return <FullPageSpinner />;
    }
    if (error) {
      return (
        <div className="d-flex justify-content-center align-items-center flex-column">
          <p>There's a problem. Try refreshing the app.</p>
          <pre>{error.message}</pre>
        </div>
      );
    }
  }

  // TODO: investigate react-async for handling typical aysnc request states
  const login = form =>
    authClient.login(form).then(data => {
      setData({ member: data.payload });
      return data;
    });

  const logout = form =>
    authClient.logout(form).then(success => {
      setData(null);
      return success;
    });

  const register = form =>
    authClient.register(form).then(data => {
      setData({ member: data.payload });
      return data;
    });

  const licenseRegister = form =>
    authClient.licenseRegister(form).then(data => {
      setData({ member: data.member });
      return data;
    });

  return <AuthContext.Provider value={{ ...data, login, logout, register, licenseRegister }} {...props} />;
}

export { AuthProvider, useAuth };
