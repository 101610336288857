import React, { useState } from 'react';
import { CardElement, injectStripe } from 'react-stripe-elements';
import './PaymentForm.css';
import { client } from '../../api/client'

function AddCardForm({ stripe }) {
  const [status, setStatus] = useState('default');
  const [managers, setManagers] = useState('')
  const [memberId, setMemberId] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  

  React.useEffect(() => {
    (async () => {
      const data = await client('admin/managers')
      if (data.error) setErrorMessage(data.message)

      setManagers(data.managers)
    })()
  }, [])

  const submit = async e => {
    e.preventDefault();

    setStatus('submitting');

    try {
      let { token } = await stripe.createToken({ name: 'Name' });
      const data = await client('add-card', { body: { token: token.id, memberId }} )

      if (!data.error) {
        setStatus('complete');
        // returned data
        // memberId
        // customerId - Stripe
        // cardId - Stripe
        // cardType
        // cardNumber - last 4 digits
        // expirationMonth
        // expirationYear
        // postalCode
      } else {
        throw new Error('Network response was not ok.');
      }
    } catch (err) {
      setStatus('error');
    }
  };

  if (status === 'complete') {
    return <div className="PaymentForm-complete">Card added</div>;
  }

  function handleChange(e) {
    setMemberId(e.target.value)
  }

  return (
    <form className="AddCardForm" onSubmit={submit}>
      <div className="col text-center">
        <h4>Add a Manager's Card</h4>
        <select onChange={handleChange}>
          <option value="">Please Select</option>
          {managers && managers.map(manager => {
            return <option key={`manager_${manager.id}`} value={manager.id}>{`${manager.firstName} ${manager.lastName} - ${manager.company} ${manager.dept}`}</option>
          })}
        </select>

        <CardElement />
        <button className="PaymentForm-button" type="submit" disabled={status === 'submitting'}>
          {status === 'submitting' ? 'Submitting' : 'Add Card'}
        </button>
        {status === 'error' && <div className="PaymentForm-error">Something went wrong.</div>}
      </div>
    </form>
  );
}

export default injectStripe(AddCardForm);
