import React, { useEffect, useState } from 'react';
import { client } from '../../api/client';
import { Link, useParams, useHistory } from 'react-router-dom';
import { Button, Container, Form, Row, Col, Dropdown } from 'react-bootstrap';
import { useMember } from '../../context/member-context';

function AdminDeptUpdate() {
  const member = useMember();
  const history = useHistory();

  const [company, setCompany] = useState('');
  const [companyId, setCompanyId] = useState('');
  const [dept, setDept] = useState('');
  const [availableLicenses, setAvailableLicenses] = useState('1');
  const [errorMessage, setErrorMessage] = useState('');
  const { id } = useParams();

  useEffect(() => {
    (async () => {
      const { dept } = await client(`admin/dept/${id}`);
      setCompany(dept.company);
      setCompanyId(dept.companyId);
      setDept(dept.name);
      setAvailableLicenses(dept.availableLicenses);
    })();
  }, []);

  async function handleSubmit(e) {
    e.preventDefault();
    setErrorMessage('');

    if (dept === '') return setErrorMessage('Please enter a dept');

    const data = await client('admin/update-dept', { body: { deptId: id, companyId, name: dept, availableLicenses } });
    if (data.error) return setErrorMessage(data.message);
    history.push(`/admin/company/${companyId}`);
  }

  if (member.role !== 'admin') return <div>{errorMessage}</div>;

  return (
    <div>
      <Form onSubmit={handleSubmit}>
        <Container fluid>
          <Row>
            <Col>
              <h1>Update Dept for {company}</h1>
              <Link to="/admin">Admin</Link> > <Link to="/admin/companies">Companies</Link> > {company}
            </Col>
          </Row>
          <Row style={{ height: '25px' }}>
            <Col>
              <Form.Group>{errorMessage && <Form.Text className="text-danger">{errorMessage}</Form.Text>}</Form.Group>
            </Col>
          </Row>
          <Row>
            <Col lg={3}>
              <Form.Group>
                <Form.Control type="text" id="dept" name="dept" value={dept} onChange={e => setDept(e.target.value)} />
              </Form.Group>
            </Col>
            <Col lg={1}>
              <Form.Group>
                <Form.Control
                  type="text"
                  id="availableLicenses"
                  name="availableLicenses"
                  value={availableLicenses}
                  onChange={e => setAvailableLicenses(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col>
              <Button variant="primary" type="submit">
                Update Dept
              </Button>
            </Col>
          </Row>
        </Container>
      </Form>
    </div>
  );
}

export default AdminDeptUpdate;
