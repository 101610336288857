import React, { useEffect, useState } from 'react';
import { client } from '../../api/client';
import { Link, useParams } from 'react-router-dom';
import { Button, Container, Form, Row, Col, Table } from 'react-bootstrap';
import { useMember } from '../../context/member-context';

function AdminCompany() {
  const member = useMember();

  const [company, setCompany] = useState('');
  const [depts, setDepts] = useState([]);
  const [dept, setDept] = useState('');
  const [availableLicenses, setAvailableLicenses] = useState('1');
  const { id } = useParams();
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    (async () => {
      if (member.role !== 'admin') return setErrorMessage('You are not authorized to access this page');
      const data = await client(`admin/company/${id}`);
      setCompany(data.company.name);
      setDepts(data.company.depts);
    })();
  }, []);

  async function handleSubmit(e) {
    e.preventDefault();
    setErrorMessage('');

    if (dept === '') return setErrorMessage('Please enter a dept');

    const data = await client('admin/create-dept', { body: { companyId: id, name: dept, availableLicenses } });
    if (data.error) return setErrorMessage(data.message);

    let existingDepts = depts;
    setDepts([]);
    existingDepts.push({
      id: data.dept.id,
      name: data.dept.name,
      availableLicenses: availableLicenses,
      manager: {}
    });
    setDepts(existingDepts);
    setDept('');
    setAvailableLicenses('1');
  }

  if (member.role !== 'admin') return <div>{errorMessage}</div>;

  return (
    <div>
      <Form onSubmit={handleSubmit}>
        <Container fluid>
          <Row>
            <Col>
              <h1>Manage Depts</h1>
              <Link to="/admin">Admin</Link> > <Link to="/admin/companies">Companies</Link> > {company}
            </Col>
          </Row>
          <Row style={{ height: '25px' }}>
            <Col>
              <Form.Group>{errorMessage && <Form.Text className="text-danger">{errorMessage}</Form.Text>}</Form.Group>
            </Col>
          </Row>
          <Row>
            <Col lg={3}>
              <Form.Group>
                <Form.Control
                  type="text"
                  placeholder="Enter dept"
                  value={dept}
                  onChange={e => setDept(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col lg={1}>
              <Form.Group>
                <Form.Control
                  type="text"
                  value={availableLicenses}
                  onChange={e => setAvailableLicenses(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col>
              <Button variant="primary" type="submit">
                Create Dept
              </Button>
            </Col>
          </Row>
          <Row>
            <Col>
              {depts.length === 0 && <div>There are current no depts</div>}
              <Table>
                <tbody>
                  <tr>
                    <td>Dept</td>
                    <td>Manager</td>
                    <td>Licenses</td>
                  </tr>
                  {depts &&
                    depts.map(dept => {
                      return (
                        <tr key={`co_${dept.id}`}>
                          <td>
                            <Link to={`/admin/dept-update/${dept.id}`}>{dept.name}</Link>
                          </td>
                          <td>
                            {dept.manager.id && (
                              <Link to={`/admin/member/${dept.manager.id}`}>
                                {dept.manager.firstName + ' ' + dept.manager.lastName}
                              </Link>
                            )}
                            {!dept.manager.id && (
                              <Link to={`/admin/create-member?deptId=${dept.id}&role=manager`}>Create Manager</Link>
                            )}
                          </td>
                          <td>
                            {dept.availableLicenses === 1
                              ? dept.availableLicenses + ' license'
                              : dept.availableLicenses + ' licenses'}
                          </td>
                        </tr>
                      );
                    })
                  }
                </tbody>
              </Table>
            </Col>
          </Row>
        </Container>
      </Form>
    </div>
  );
}

export default AdminCompany;
