import React from 'react';
// import MenuTree from "./menuTree";
// import { smoothlyMenu } from "./helpers/helpers";
import MenuItem from './MenuItem';
import LogoWithText from '../theme/assets/img/brand/jetdata-logo-text-light.png';
import Logo from '../theme/assets/img/brand/jetdata-logo.png';
import { useMember } from '../context/member-context'
import { Link } from "react-router-dom";

const Navigation = () => {
  const member = useMember();
  
  return (
    <nav className="navbar-default navbar-static-side" role="navigation">
      <div className="sidebar-collapse">
        <ul className="nav metismenu" id="side-menu">
          <li className="nav-header">
            <div className="dropdown profile-element" style={{ backgroundColor: '#2f4050' }}>
              <Link to='/'><img className="img-fluid" src={LogoWithText} alt="Jet Data logo with text" /></Link>
            </div>
            <div className="logo-element" style={{ backgroundColor: '#2f4050' }}>
              <Link to='/'><img className="img-fluid" src={Logo} alt="Jet Data logo" /></Link>
            </div>
          </li>
          <MenuItem path="/" icon="home" label="Home" />
          { member.role === 'admin' && <MenuItem path="/admin" icon="bank" label="Administration" /> }
          <MenuItem path="/package-editor" icon="archive" label="Package Editor" />
          <MenuItem path="/parts-explorer" icon="binoculars" label="Explore" />
          <MenuItem path="/member/manage-watchlist" icon="eye" label="Watchlists" />
        </ul>
      </div>
    </nav>
  );
};

export default Navigation;
