import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { useMember } from './context/member-context';
import AuthenticatedApp from './app-authenticated';
import UnauthenticatedApp from './app-unauthenticated';

function App() {
  const member = useMember();
  return <Router>{member ? <AuthenticatedApp /> : <UnauthenticatedApp />}</Router>;
}

export default App;
