import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Form, Button } from 'react-bootstrap';
import { Card, CardBody } from '../../components/Card';
import { changePassword } from '../../api/member-client';
import { useMember } from '../../context/member-context';
import ReactGA from 'react-ga';

function validatePassword(password, name) {
  if (!password) return { error: true, message: `${name} field is required.` };
  if (password.length < 6) return { error: true, message: `${name} field must be six or more characters.` };

  return { error: false };
}

function validateForm({ newPassword, verifyPassword }) {
  let currentValidation;

  currentValidation = validatePassword(newPassword, 'New password');
  if (currentValidation.error) {
    return currentValidation;
  }

  currentValidation = validatePassword(verifyPassword, 'Verify password');
  if (currentValidation.error) {
    return currentValidation;
  }

  if (verifyPassword !== newPassword) {
    return { error: true, message: 'New password and verify password must match.' };
  }

  return { error: false };
}

function ChangePassword() {
  const member = useMember();

  const [isLoading, setIsLoading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(null);
  const [isSubmitted, setIsSubmitted] = React.useState(false);
  const [newPassword, setNewPassword] = React.useState('');
  const [verifyPassword, setVerifyPassword] = React.useState('');

  React.useEffect(() => {
    if (member) {
      ReactGA.initialize({
        trackingId: process.env.REACT_APP_GA_TRACKING_ID,
        title: 'Change Password',
        pageview: window.location.pathname,
        gaOptions: {
          username: member.username
        }
      });
    }
  }, [member]);

  function handleSubmit(e) {
    e.preventDefault();
    setErrorMessage('');

    const form = { newPassword, verifyPassword };
    const { error: formError, message } = validateForm(form);

    if (formError) {
      ReactGA.event({
        category: 'Change Password',
        action: 'Failed Attempt',
        label: member.username
      });

      setErrorMessage(message);
    } else {
      setIsLoading(true);
      changePassword(form)
        .then(({ error, message }) => {
          if (error) {
            ReactGA.event({
              category: 'Change Password',
              action: 'Failed Attempt',
              label: member.username
            });

            setErrorMessage(message);
          } else {
            ReactGA.event({
              category: 'Change Password',
              action: 'Successfully Changed',
              label: member.username
            });

            setIsSubmitted(true);
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }

  return (
    <Row>
      <Col md={{ span: 8, offset: 2 }} lg={{ span: 4, offset: 4 }}>
        <Card>
          <CardBody>
            <h3 className="m-b text-center">Change Password</h3>
            {isSubmitted ? (
              <>
                <p className="text-dark">Your password has been successfully updated.</p>
                <Link to="/member">Back to Profile</Link>
              </>
            ) : (
              <Form onSubmit={handleSubmit}>
                <Form.Group>
                  <Form.Label>New Password</Form.Label>
                  <Form.Control
                    size="sm"
                    type="password"
                    placeholder="New password"
                    value={newPassword}
                    onChange={e => setNewPassword(e.target.value)}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Verify New Password</Form.Label>
                  <Form.Control
                    size="sm"
                    type="password"
                    placeholder="Verify new password"
                    value={verifyPassword}
                    onChange={e => setVerifyPassword(e.target.value)}
                  />
                </Form.Group>
                <Form.Group>{errorMessage && <Form.Text className="text-danger">{errorMessage}</Form.Text>}</Form.Group>
                <Form.Group className="text-center">
                  <Button variant="primary" type="submit" disabled={isLoading}>
                    Change Password
                  </Button>
                </Form.Group>
              </Form>
            )}
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
}

export default ChangePassword;
