import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import Profile from './Profile';
import ChangePassword from './ChangePassword';
import ChangeProfile from './ChangeProfile';
import ManageWatchList from './ManageWatchlist';

function MemberRoutes() {
  return (
    <section className="m-t-md pb-4">
      <Container fluid>
        <Switch>
          <Route path="/member" exact={true}>
            <Profile />
          </Route>
          <Route path="/member/change-password">
            <ChangePassword />
          </Route>
          <Route path="/member/change-profile">
            <ChangeProfile />
          </Route>
          <Route path="/member/manage-watchlist">
            <ManageWatchList />
          </Route>
          <Route path="/member/*">
            <Redirect to="/member" />
          </Route>
        </Switch>
      </Container>
    </section>
  );
}

export default MemberRoutes;
