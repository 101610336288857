// import { client } from './client';
import { topTransactedParts } from './mockdata/dashboard-top-transacted';
import { watchListParts } from './mockdata/dashboard-watchlist';

function getTopTransactedParts(partNumber) {
  return new Promise((resolve, reject) => {
    setTimeout(() => {      
      return resolve(topTransactedParts);
    }, 200);
  });
}

function getMemberWatchList(partNumber) {
  return new Promise((resolve, reject) => {
    setTimeout(() => {      
      return resolve(watchListParts);
    }, 200);
  });
}

export { getTopTransactedParts, getMemberWatchList };
