const TOKEN_KEY = process.env.tokenKey || '__token__';
const SERVER_ERROR_MESSAGE = 'We experienced an issue. Please try again. If the problem persists, please contact us.';

function getToken() {
  return window.localStorage.getItem(TOKEN_KEY);
}

function setToken(token) {
  if (token) return window.localStorage.setItem(TOKEN_KEY, token);
}

function removeToken() {
  return window.localStorage.removeItem(TOKEN_KEY);
}

function handleTokenRefresh(r) {
  const token = r.headers.get('Authorization');
  if (token !== null) {
    if (token === '') {
      if (window.location.pathname !== '/') {
        window.location = '/'; // on a token reset we hard reload the home route which will make a call to getInitAppData() and inform the user that their session has expired.
      }
    } else {
      const formattedToken = token.split('Bearer ')[1];
      setToken(formattedToken); // server issued a new token. Replace the old one
    }
  }
  return r;
}

// NOTE: This is the base client used to request data. We configure it here. If it needs to be replaced in the future
// the dependent api resources should not have to change much.
// - baseAPI url
// - tokenKey
// - fetching lib
function client(endpoint, { body, ...customConfig } = {}) {
  const token = getToken();
  const headers = {
    'Content-Type': 'application/json',
    Mode: 'cors',
    Accept: 'application/json',
    Cache: 'no-cache'
  };
  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }
  const config = {
    method: body ? 'POST' : 'GET',
    ...customConfig,
    headers: {
      ...headers,
      ...customConfig.headers
    }
  };
  if (body) {
    config.body = JSON.stringify(body);
  }

  // This handles the timeout on server crash or uncaught exceptions in request pipeline
  const controller = new AbortController();
  config.signal = controller.signal;
  const timeoutId = setTimeout(() => controller.abort(), process.env.REACT_APP_REQUEST_TIMEOUT || 5000);

  return window
    .fetch(`${process.env.REACT_APP_API_URL + endpoint}`, config)
    .then(resp => {
      clearTimeout(timeoutId);
      return resp;
    })
    .then(handleTokenRefresh)
    .then(resp => resp.json())
    .catch(() => {
      // TODO: Should we log the error? We should have already logged it on the server unless it is a parsing error due to bad JSON?
      // Note: This allows the promise chain to continue uninterupted.
      // The caller should always expect a {error: true | false, message: message | undefined, payload: value | undefined }
      return { error: true, message: SERVER_ERROR_MESSAGE, payload: null };
    });
}

export { getToken, setToken, removeToken, client };
