import { Link } from 'react-router-dom';
import React from 'react';
import { Table } from 'react-bootstrap';
import { useMember } from '../context/member-context';
import { Card, CardTitle, CardBody } from '../components/Card';
import { getMemberWatchList } from '../api/dashboard-client';
import { getTopTransactedParts } from '../api/parts-client';
import { getMemberWatchlists, getWatchlistDetails } from '../api/watchlist-client';
import ReactGA from 'react-ga';

// ===== UI Components
const Spinner = () => (
  <div style={{ minHeight: '500px', display: 'flex', alignItems: 'center' }}>
    <div className="sk-spinner sk-spinner-wandering-cubes">
      <div className="sk-cube1"></div>
      <div className="sk-cube2"></div>
    </div>
  </div>
);

const Error = ({ error }) => (
  <div style={{ minHeight: '500px', display: 'flex', alignItems: 'center' }}>
    <div>
      <h4 className="text-danger">An error Occured</h4>
      {error}
    </div>
  </div>
);

// ===== Dashboard Modules =====
function TopTransactedParts({ data, member }) {
  const { payload: topTransactedParts } = data;
  const ownCompanyStyle = { color: '#28a745' };
  return (
    <table className="table">
      <thead>
        <tr>
          <th>Rank</th>
          <th>PN</th>
          <th>Activity</th>
          <th style={ownCompanyStyle}>{member.companyId.name}</th>
        </tr>
      </thead>
      <tbody className="text-sm">
        {topTransactedParts.length === 0 ? (
          <tr>No Data Available</tr>
        ) : (
          topTransactedParts
            .sort((a, b) => b.eventVolume - a.eventVolume)
            .map((topTransactedPart, i) => (
              <tr key={topTransactedPart._id}>
                <td>{i + 1}</td>
                <td>
                  <Link to={`/part/${topTransactedPart._id}`}>{topTransactedPart._id}</Link>
                </td>
                <td>{topTransactedPart.eventVolume}</td>
                <td style={ownCompanyStyle}>{topTransactedPart.companyVolume}</td>
              </tr>
            ))
        )}
      </tbody>
    </table>
  );
}

function WatchListParts() {
  const [isLoading, setIsLoading] = React.useState(true);
  const [error, setError] = React.useState(null);
  const [watchlistDetails, setWatchlistDetails] = React.useState(null);

  React.useEffect(() => {
    getMemberWatchlists()
      .then(({ error, message, payload }) => {
        if (error) {
          setError(message);
          return null;
        } else if (payload && payload.length && payload.length > 0) {
          return getWatchlistDetails(payload[0].id);
        }
      })
      .then(response => {
        if (response) {
          const { error, message, payload } = response;
          if (error) {
            setError(message);
            return;
          } else {
            setWatchlistDetails(payload);
            return;
          }
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  if (isLoading) {
    return null;
  }

  return (
    <>
      {error ? (
        <div>{error}</div>
      ) : (
        <Table size="sm" responsive>
          <thead>
            <tr>
              <th>Rank</th>
              <th>PN</th>
              <th>Activity</th>
            </tr>
          </thead>
          {!watchlistDetails && (
            <tbody>
              <tr>
                <td colSpan="3">
                  You do not have a watch list. <Link to="/member/manage-watchlist">Create one</Link> to subscribe to
                  changes in part activity.
                </td>
              </tr>
            </tbody>
          )}
          {watchlistDetails && (
            <tbody className="text-sm">
              {watchlistDetails.parts.length === 0 ? (
                <tr>
                  <td colSpan="3">
                    No parts in watchlist. Add parts by visiting the part details page and clicking the "Add to
                    Watchlist" Button.
                  </td>
                </tr>
              ) : (
                <>
                  {watchlistDetails.parts
                    .sort((a, b) => b.activity - a.activity)
                    .map((part, i) => (
                      <tr key={part.partNumber}>
                        <td>{i + 1}</td>
                        <td>
                          <Link to={`/part/${part.partNumber}`}>{part.partNumber}</Link>
                        </td>
                        <td>{part.activity}</td>
                      </tr>
                    ))}
                </>
              )}
            </tbody>
          )}
        </Table>
      )}
    </>
  );
}

// ===== Dashboard Container ====
function Dashboard() {
  // ui state
  const member = useMember();

  const [isLoading, setIsLoading] = React.useState(true);
  const [error, setError] = React.useState(null);

  // dashboard domain state
  const [topTransactedParts, setTopTransactedParts] = React.useState(null);
  const [memberWatchList, setMemberWatchList] = React.useState(null);

  React.useEffect(() => {
    if (member) {
      ReactGA.initialize({
        trackingId: process.env.REACT_APP_GA_TRACKING_ID,
        title: 'Dashboard',
        pageview: '/',
        gaOptions: {
          username: member.username
        }
      });
    }
  }, [member]);

  React.useEffect(() => {
    let isCurrent = true;
    Promise.all([getTopTransactedParts(), getMemberWatchList()])
      .then(resolvedDataArray => {
        if (!isCurrent) return;

        const [topTransactedPartsData, memberWatchListData] = resolvedDataArray;
        setTopTransactedParts(topTransactedPartsData);
        setMemberWatchList(memberWatchListData);
      })
      .catch(anyError => {
        if (!isCurrent) return;
        console.log('==== Error in Dashboard ====');
        console.log(anyError);
        setError(anyError);
      })
      .finally(() => {
        if (!isCurrent) return;
        setIsLoading(false);
      });

    return () => {
      isCurrent = false;
    };
  }, [member]);

  if (isLoading) {
    return (
      <section className="m-t-md pb-4">
        <Spinner />
      </section>
    );
  }

  if (error) {
    return (
      <section className="m-t-md pb-4">
        <Error />
      </section>
    );
  }

  return (
    <section className="m-t-md pb-4">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-4">
            <Card>
              <CardTitle>
                <h5>
                  <span className="font-bold text-danger mr-2">New!</span>Package Editor
                </h5>
              </CardTitle>
              <CardBody>
                <div className="text-center">
                  <Link className="btn btn-success btn-sm" to="/package-editor">
                    <i className="fa fa-archive"></i>
                    <span>Go to Package Editor</span>
                  </Link>
                </div>
              </CardBody>
            </Card>
          </div>
          <div className="col-md-4">
            <Card>
              <CardTitle>
                <h5>
                  <span className="font-bold text-danger mr-2">New!</span>Parts Explorer
                </h5>
              </CardTitle>
              <CardBody>
                <div className="text-center">
                  <Link className="btn btn-success btn-sm" to="/parts-explorer">
                    <i className="fa fa-archive"></i>
                    <span>Go to Parts Explorer</span>
                  </Link>
                </div>
              </CardBody>
            </Card>
          </div>
          <div className="col-md-4">
            <Card>
              <CardTitle>
                <h5>
                  <span className="font-bold text-danger mr-2">New!</span>Watchlist Manager
                </h5>
              </CardTitle>
              <CardBody>
                <div className="text-center">
                  <Link className="btn btn-success btn-sm" to="/member/manage-watchlist">
                    <i className="fa fa-archive"></i>
                    <span>Go to Watchlist Manager</span>
                  </Link>
                </div>
              </CardBody>
            </Card>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 col-md-6">
            <Card>
              <CardTitle>
                <h5>Active Parts</h5>
              </CardTitle>
              <CardBody>
                <TopTransactedParts data={topTransactedParts} member={member} />
              </CardBody>
            </Card>
          </div>
          <div className="col-sm-12 col-md-6">
            <Card>
              <CardTitle>
                <h5>Watchlist</h5>
              </CardTitle>
              <CardBody>
                <WatchListParts data={memberWatchList} />
              </CardBody>
            </Card>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Dashboard;
