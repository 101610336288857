import React, { useState, useEffect } from 'react';
import { useMember } from '../context/member-context';
import { Link } from 'react-router-dom';
import { client } from '../api/client';
import { OutTable, ExcelRenderer } from 'react-excel-renderer';
import { Button, Modal, Row, Col, Table } from 'react-bootstrap';
import { formatNumberToDollarAmount } from './PartDetail/shared';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import JetDataUploadFile from '../files/jetdatauploadfile.xlsx';
import { Spinner } from '../components/Spinner';

function PackageEditor() {
  const member = useMember();
  const [errorMessage, setErrorMessage] = useState('');
  const [warningMessage, setWaringMessage] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [status, setStatus] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [parts, setParts] = useState([]);
  const [fileName, setFileName] = useState('');
  const [totalValue, setTotalValue] = useState('');
  const [globalDiscountInput, setGlobalDiscountInput] = useState('');
  const [globalDiscountPct, setGlobalDiscountPct] = useState(0);

  async function handleFileChosen(file) {
    setErrorMessage('');
    if (file === '') {
      setStatus('');
      return setErrorMessage('Please select a file');
    }
    if (file.size === 0) {
      setStatus('');
      return setErrorMessage('The selected file is empty.  Please select another file');
    }

    setIsLoading(true);
    setFileName(file.name);

    ExcelRenderer(file, (err, resp) => {
      if (!resp) return setErrorMessage('There was an error processing your file');
      if (resp.cols.length === 0 || resp.rows.length === 0)
        return setErrorMessage('There was an error processing your file');

      // JavaScript indexOf begins at 0 | columns must be greater than 0
      let partNumberCol = -1;
      let quantityCol = -1;
      let conditionCodeCol = -1;
      let customPriceCol = -1;
      let sanitizedHeader;
      resp.rows[0].map((colHeader, i) => {
        sanitizedHeader = colHeader
          .trim()
          .toLowerCase()
          .replace(/  +/g, ' '); // replace double spaces with single spaces
        sanitizedHeader = sanitizedHeader.trim().replace(/\s/g, '-'); // replace single spaces with hyphens
        sanitizedHeader = sanitizedHeader.trim().replace(/[^a-z-0-9.-]+/gi, ''); // remove non-alphanumeric characters

        if (sanitizedHeader === 'part-number') partNumberCol = i;
        if (sanitizedHeader === 'quantity' || sanitizedHeader === 'qty') quantityCol = i;
        if (sanitizedHeader === 'condition-code' || sanitizedHeader === 'condition') conditionCodeCol = i;
        if (sanitizedHeader === 'custom-price') customPriceCol = i;
      });

      if (partNumberCol === -1) return setErrorMessage('A part number column was not found');

      let warningMessageAry = [...warningMessage];

      if (quantityCol === -1) {
        warningMessageAry.push('A quantity column was not found');
      }

      if (conditionCodeCol === -1) {
        warningMessageAry.push('A condition code column was not found and parts where set to OH');
      }

      setWaringMessage(warningMessageAry);

      let partsList = [];
      resp.rows.map((data, i) => {
        if (i > 0 && typeof data[partNumberCol] !== 'undefined')
          partsList.push(
            data[partNumberCol]
              .toString()
              .trim()
              .toUpperCase()
          );
      });

      // Produces a distinct part list
      partsList = partsList.filter((x, i, a) => a.indexOf(x) == i);
      let found;
      let totalValue = 0;
      client('parts/fmv', { body: { partNumbers: partsList.toString() } }).then(returnedData => {
        if (returnedData.error) return setErrorMessage(returnedData.message);
        if (!returnedData.parts) return setErrorMessage('We experienced an error processing your request');

        let partsDisplay = [];
        let partNumber,
          quantity,
          conditionCode,
          sales,
          partInfo,
          price,
          customPrice,
          customPriceInput,
          total,
          fmvSale,
          ohFmvRepair,
          svFmvRepair;

        resp.rows.map((data, i) => {
          partNumber = data[partNumberCol];
          price = 0;
          total = 0;
          customPrice = '';
          customPriceInput = '';

          if (quantityCol !== -1 && typeof data[quantityCol] !== 'undefined') {
            quantity = Number(data[quantityCol]);
          } else {
            quantity = 1;
          }

          if (
            conditionCodeCol !== -1 &&
            typeof data[conditionCodeCol] !== 'undefined' &&
            data[conditionCodeCol] !== ''
          ) {
            conditionCode = data[conditionCodeCol];
          } else {
            conditionCode = 'OH';
          }

          if (customPriceCol !== -1 && typeof data[customPriceCol] !== 'undefined') {
            customPrice = data[customPriceCol];
            customPriceInput = data[customPriceCol];
          } else {
            customPrice = '';
            customPriceInput = '';
          }

          partInfo = returnedData.parts.filter(p => p.partNumber === partNumber)[0];
          if (typeof partInfo !== 'undefined') {
            found = true;
            sales = partInfo.sales.filter(s => s.value !== null);
            if (partInfo.sales.filter(s => s.conditionCode === conditionCode)[0] !== 'undefined') {
              fmvSale = Number(partInfo.sales.filter(s => s.conditionCode === conditionCode)[0].value);
              price = Number(partInfo.sales.filter(s => s.conditionCode === conditionCode)[0].value);
            }
            ohFmvRepair = Number(partInfo.repairs.filter(r => r.conditionCode === 'OH')[0].value);
            svFmvRepair = Number(partInfo.repairs.filter(r => r.conditionCode === 'SV')[0].value);
          } else {
            found = false;
            sales = [];
            price = 0;
            fmvSale = 'n/a';
            ohFmvRepair = 'n/a';
            svFmvRepair = 'n/a';
          }

          if (price > 0 && quantity > 0) {
            totalValue = totalValue + price * quantity;
            total = price * quantity;
          } else {
            total = 0;
          }

          // Account for file header columns
          if (i > 0)
            partsDisplay.push({
              partNumber,
              originalPrice: price,
              price,
              discountPct: '',
              discountPctInput: '',
              customPrice,
              customPriceInput,
              quantity,
              total,
              conditionCode,
              fmvSale,
              ohFmvRepair,
              svFmvRepair,
              sales,
              found
            });
        });
        setParts(partsDisplay);
        setTotalValue(Number(totalValue));
        setTimeout(() => {
          setIsLoading(false);
        }, 350);
      });
    });
  }

  function updateConditionCode(i, value) {
    let existingParts = [...parts];
    existingParts.map((part, j) => {
      if (i === j) {
        part.conditionCode = value;
        if (part.sales.length !== 0) {
          let fmvPrice = part.sales.filter(s => s.conditionCode === value)[0];
          if (typeof fmvPrice !== 'undefined') {
            part.fmvSale = fmvPrice.value;
          } else {
            part.fmvSale = 'n/a'
          }
        }
      }
    });

    setParts(existingParts);
  }

  function updateCustomPrice(i, value) {
    let totalValue = 0;
    let appliedDiscountPct = 0;
    let partTotal = 0;
    let existingParts = [...parts];
    let sanitizedValue = value.replace(/[^0-9.]+/gi, '');
    if (sanitizedValue) sanitizedValue = Number(sanitizedValue);

    existingParts.map((part, j) => {
      if (i === j) {
        part.customPrice = value.replace(/[^$0-9.,]+/gi, '');

        if (!sanitizedValue || sanitizedValue === 0) {
          sanitizedValue = part.originalPrice;
        }

        if (part.discountPct) {
          appliedDiscountPct = (100 - part.discountPct) / 100;

          partTotal = Number(sanitizedValue) * part.quantity * appliedDiscountPct;
          part.price = Number(sanitizedValue) * appliedDiscountPct;
          part.total = partTotal;
        } else {
          partTotal = Number(sanitizedValue) * part.quantity;
          part.price = Number(sanitizedValue);
          part.total = partTotal;
        }
      }

      if (part.price > 0 && part.quantity > 0) {
        totalValue += partTotal;
      }
    });

    setParts(existingParts);
    setTotalValue(totalValue);
  }

  function updateDiscountPct(i, value) {
    let totalValue = 0;
    let existingParts = [...parts];
    let inputValue = value;

    if (value > 99 || value === 0) {
      value = 0;
    } else {
      value = Number((100 - value) / 100);
    }

    let price;
    existingParts.map((part, j) => {
      if (i === j) {
        price = part.customPrice ? part.customPrice : part.originalPrice;

        part.discountPctInput = inputValue;
        part.discountPct = value;

        if (value !== 0) {
          part.price = price * value;
          part.total = price * part.quantity * value;
        } else if (value === 0 && globalDiscountPct !== 0) {
          part.price = price * globalDiscountPct;
          part.total = price * part.quantity * globalDiscountPct;
        } else {
          part.price = price;
          part.total = price * part.quantity;
        }
      }

      totalValue += part.total;
    });

    setParts(existingParts);
    setTotalValue(totalValue);
  }

  function updateGlobalDiscount(value) {
    let totalValue = 0;
    let existingParts = [...parts];
    let inputValue = value;

    if (inputValue === '') value = 0;
    if (value > 99) {
      value = 0;
      setGlobalDiscountInput(inputValue);
    } else {
      value = Number((100 - value) / 100);
    }

    setGlobalDiscountInput(inputValue);
    setGlobalDiscountPct(value);

    let price;
    existingParts.map((part, j) => {
      price = part.customPrice ? part.customPrice : part.originalPrice;
      if (part.discountPct) {
        part.price = price * part.discountPct;
        part.total = price * part.quantity * part.discountPct;
      } else if (value !== 0) {
        part.price = price * value;
        part.total = price * part.quantity * value;
      } else {
        part.price = price;
        part.total = price * part.quantity;
      }
      totalValue += part.total;
    });

    setParts(existingParts);
    setTotalValue(totalValue);
  }

  function resetFile() {
    setGlobalDiscountPct(0);
    setGlobalDiscountInput('');
    setErrorMessage('');
    setWaringMessage([]);
    setParts([]);
  }

  function exportFile() {
    let exportedFile = fileName.toLowerCase();
    exportedFile = exportedFile.replace(/  +/g, ' ');
    exportedFile = exportedFile.replace(/\s/g, '-');
    exportedFile = exportedFile.replace(/[^a-z-0-9.-_]+/gi, '');
    if (exportedFile === '' || exportedFile.indexOf('.') === -1) exportedFile = 'export.xlsx';

    let existingFileName = exportedFile.split('.');
    exportedFile = '';
    for (let i = 0; i < existingFileName.length - 1; i++) {
      exportedFile = exportedFile + existingFileName[i] + '.';
    }
    exportedFile = exportedFile.substr(0, exportedFile.length - 1);

    let partsList = [];
    parts.map((part, i) => {
      partsList.push({
        'Part Number': part.partNumber,
        'Condition Code': part.conditionCode,
        Price: formatNumberToDollarAmount(part.price),
        Quantity: part.quantity,
        Total: formatNumberToDollarAmount(part.total),
        '% Discount': part.discountPct ? part.discountPct : '-',
        'Custom Price': formatNumberToDollarAmount(part.customPrice),
        'FMV (Sale)': formatNumberToDollarAmount(part.fmvSale),
        'OH FMV (Repair)': formatNumberToDollarAmount(part.ohFmvRepair),
        'SV FMV (Repair)': formatNumberToDollarAmount(part.svFmvRepair)
      });
    });

    const ws = XLSX.utils.json_to_sheet(partsList);
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: 'xlsx' });
    FileSaver.saveAs(data, exportedFile + '.xlsx');
  }

  return (
    <div>
      {isLoading && (
        <div>
          <Spinner />
        </div>
      )}

      {!isLoading && parts.length === 0 && status === '' && (
        <div style={{ height: '65px' }}>
          <div style={{ height: '20px', color: '#ff0000' }}>{errorMessage}</div>
          <div>
            Use the below button to upload a file. <button onClick={() => setShowModal(true)}>Info</button>
          </div>
          <input type="file" accept=".xlsx,.xls,.csv" onChange={e => handleFileChosen(e.target.files[0])} />
        </div>
      )}
      {status !== '' && <div style={{ height: '35px' }}>{status}</div>}

      {!isLoading && parts && parts.length !== 0 && (
        <>
          <Table className="table-sm" className="mt-5" bordered={false}>
            <tbody>
              <tr>
                <td width="20%">
                  <span onClick={() => resetFile()} className="link">
                    Select a different file
                  </span>
                  <br />
                  {fileName}
                </td>
                <td width="10%">
                  Total Value:
                  <br />
                  {formatNumberToDollarAmount(totalValue)}
                </td>
                <td width="10%">
                  Global % Discount:
                  <br />
                  <input
                    type="number"
                    value={globalDiscountInput}
                    style={{ width: '45px' }}
                    onChange={e => updateGlobalDiscount(e.target.value)}
                  />
                </td>
                <td width="60%">
                  <Button onClick={() => exportFile()}>
                    Export
                  </Button>
                </td>
              </tr>
              <tr>
                <td colSpan="5">
                  {warningMessage && warningMessage.length !== 0 && (
                    <div className="alert-warning p-2">
                      {warningMessage &&
                        warningMessage.map((warning, i) => {
                          return <div key={`wng_msg_${i}`}>{warning}</div>;
                        })}
                      <span className="link" onClick={() => setShowModal(true)}>
                        More Info...
                      </span>
                    </div>
                  )}
                </td>
              </tr>
            </tbody>
          </Table>
          <Table className="table-sm" className="mt-1" striped>
            <thead>
              <tr>
                <th width="8%">Part Number</th>
                <th width="7%" nowrap="true">
                  Condition Code
                </th>
                <th width="5%">Price</th>
                <th width="5%">Qty</th>
                <th width="7%" nowrap="true">
                  Total
                </th>
                <th width="7%" nowrap="true">
                  % Discount
                </th>
                <th width="7%" nowrap="true">
                  Custom Price
                </th>
                <th width="7%" nowrap="true">
                  FMV (Sale)
                </th>
                <th width="7%" nowrap="true">
                  OH FMV (Repair)
                </th>
                <th width="7%" nowrap="true">
                  SV FMV (Repair)
                </th>
              </tr>
            </thead>
            <tbody>
              {parts.map((part, i) => {
                return (
                  <tr key={`part_ord_${i}`}>
                    <td>
                      {part.found ? (
                        <Link target="_blank" to={`/part/${part.partNumber}`}>
                          {part.partNumber}
                        </Link>
                      ) : (
                        part.partNumber
                      )}
                    </td>
                    <td>
                      {part.sales.length !== 0 ? (
                        <select
                          onChange={e => updateConditionCode(i, e.target.value, part.sales)}
                          value={part.conditionCode}
                        >
                          <option>NS</option>
                          <option>OH</option>
                          <option>SV</option>
                          <option>AR</option>
                          <option>BER</option>
                        </select>
                      )
                      : part.conditionCode }
                    </td>
                    <td>{formatNumberToDollarAmount(part.price)}</td>
                    <td>{part.quantity}</td>
                    <td>{formatNumberToDollarAmount(part.total)}</td>
                    <td>
                      <input
                        type="number"
                        value={part.discountPctInput}
                        onChange={e => updateDiscountPct(i, e.target.value)}
                        style={{ width: '45px' }}
                      />
                    </td>
                    <td>
                      <input
                        type="number"
                        value={part.customPrice}
                        onChange={e => updateCustomPrice(i, e.target.value)}
                        style={{ width: '75px' }}
                      />
                    </td>
                    <td>{formatNumberToDollarAmount(part.fmvSale)}</td>
                    <td>{formatNumberToDollarAmount(part.ohFmvRepair)}</td>
                    <td>{formatNumberToDollarAmount(part.svFmvRepair)}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </>
      )}
      <br />
      <br />
      <br />

      <Modal onHide={() => setShowModal(false)} show={showModal}>
        <>
          <Modal.Header closeButton>
            <Modal.Title>File Format</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Uploaded file must be formatted in .xls or .xlsx.
            <br />
            <br />
            <a href={JetDataUploadFile}>Download Sample Template Here</a>
            <br />
            <br />
            <span style={{ fontWeight: 'bold' }}>Part Number</span>{' '}
            <span style={{ color: '#ff0000' }}>** required</span>
            <br />
            Enter part number. Not recognized part numbers will still be included in the package but without any system
            data.
            <br />
            <br />
            <span style={{ fontWeight: 'bold' }}>Condition Code</span>{' '}
            <span style={{ color: '#32CD32' }}>** optional</span>
            <br />
            Empty field will default to OH, which is editable in the package. Valid condition codes are the following:
            <br />
            <br />
            NE New
            <br />
            NS New Surplus
            <br />
            OH Overhauled
            <br />
            SV Serviceable
            <br />
            AR As Removed
            <br />
            BER Beyond Economic Repair
            <br />
            <br />
            <span style={{ fontWeight: 'bold' }}>Quantity</span> <span style={{ color: '#32CD32' }}>** optional</span>
            <br />
            Empty field will default to 1.
            <br />
            <br />
            <span style={{ fontWeight: 'bold' }}>Custom Price</span>{' '}
            <span style={{ color: '#32CD32' }}>** optional</span>
            <br />
            All entered price is in USD currency.
            <br />
            Entered price will be the default price overriding the system Fair Market Value. Empty field will default to
            the system Fair Market Value. If the JetData platform does not have a system Fair Market Value, the default
            will be set to 0.
          </Modal.Body>
        </>
      </Modal>
    </div>
  );
}

export default PackageEditor;
