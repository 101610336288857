import React, { useState } from 'react';
import { Switch, Route, Link, Redirect, useLocation } from 'react-router-dom';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import CookieBanner from 'react-cookie-banner';

import { useAuth } from './context/auth-context';
import { forgotPassword, resetPassword } from './api/auth-client';
import Invitee from './pages/Invitee';
import SharedPart from './pages/SharedPart';
import Logo from './theme/assets/img/brand/jetdata-logo-text.png';
import ReactGA from 'react-ga';
import ErrorPage from './pages/ErrorPage'

function Login() {
  const { login } = useAuth();

  const [errorMessage, setErrorMessage] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  React.useEffect(() => {
    ReactGA.initialize({
      trackingId: process.env.REACT_APP_GA_TRACKING_ID,
      title: 'Login',
      pageview: 'login'
    });
  }, []);

  function handleSubmit(e) {
    e.preventDefault();

    setErrorMessage('');
    login({ email, password }).then(({ error, message, url }) => {
      if (error) {
        ReactGA.event({
          category: 'Login',
          action: 'Failed Attempt'
        });

        return setErrorMessage(message);
      }

      ReactGA.event({
        category: 'Login',
        action: 'Successfully Logged In'
      });
    });
  }

  return (
    <Container>
      <Row>
        <Col md={{ span: 8, offset: 2 }} className="mt-5">
          <div className="text-center">
            <img src={Logo} className="img-fluid" alt="Jet Data" />
            <p className="mt-3 text-dark">Welcome to Jet Data! Please login to continue.</p>
          </div>
          <Form onSubmit={handleSubmit} className="mx-5">
            <Form.Group controlId="formBasicEmail">
              <Form.Label>Email address</Form.Label>
              <Form.Control type="text" placeholder="Enter email" onChange={e => setEmail(e.target.value)} />
            </Form.Group>
            <Form.Group controlId="formBasicPassword">
              <Form.Label>Password</Form.Label>
              <Form.Control type="password" placeholder="Password" onChange={e => setPassword(e.target.value)} />
            </Form.Group>
            <Form.Group>{errorMessage && <Form.Text className="text-danger">{errorMessage}</Form.Text>}</Form.Group>
            <Form.Group>
              <Button variant="primary" type="submit">
                Login
              </Button>
              <Link to="/forgot-password" className="ml-1">
                Forgot password?
              </Link>
            </Form.Group>
          </Form>
        </Col>
      </Row>
    </Container>
  );
}

function ForgotPassword() {
  const [errorMessage, setErrorMessage] = useState('');
  const [email, setEmail] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);

  React.useEffect(() => {
    ReactGA.initialize({
      trackingId: process.env.REACT_APP_GA_TRACKING_ID,
      title: 'Forgot Password',
      pageview: 'forgot-password'
    });
  }, []);

  function handleSubmit(e) {
    e.preventDefault();
    setErrorMessage('');
    forgotPassword({ email }).then(({ error, message }) => {
      if (error) {
        ReactGA.event({
          category: 'Forgot Password',
          action: 'Failed Attempt'
        });

        setErrorMessage(message);
      } else {
        ReactGA.event({
          category: 'Forgot Password',
          action: 'Successfully Reset Password'
        });

        setIsSubmitted(true);
      }
    });
  }

  return (
    <Container>
      <Row>
        <Col md={{ span: 8, offset: 2 }} className="mt-5">
          <div className="text-center">
            <Link to='/'><img src={Logo} className="img-fluid" alt="Jet Data" /></Link>
            {isSubmitted ? (
              <>
                <p className="mt-3 text-dark">
                  Instructions have been sent to reset your password. Please check your email.
                </p>
                <Link to="/">Back to sign in</Link>
              </>
            ) : (
              <p className="mt-3 text-dark">
                Forgot your password? No problem! Please enter your email and we will send you a link to reset it.
              </p>
            )}
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={{ span: 8, offset: 2 }}>
          {!isSubmitted && (
            <Form onSubmit={handleSubmit} className="mx-5">
              <Form.Group controlId="formBasicEmail">
                <Form.Label>Email address</Form.Label>
                <Form.Control type="text" placeholder="Enter email" onChange={e => setEmail(e.target.value)} />
              </Form.Group>
              <Form.Group>{errorMessage && <Form.Text className="text-danger">{errorMessage}</Form.Text>}</Form.Group>
              <Form.Group className="text-center">
                <Button variant="primary" type="submit">
                  Request Password Reset
                </Button>
              </Form.Group>
            </Form>
          )}
        </Col>
      </Row>
    </Container>
  );
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function ResetPassword() {
  const query = useQuery();
  const id = query.get('id');
  const key = query.get('key');

  const [errorMessage, setErrorMessage] = useState('');
  const [password, setPassword] = useState('');
  const [verifyPassword, setVerifyPassword] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);

  function handleSubmit(e) {
    // TODO: Client side validation [deferred until later time 02/17/2020 - Humberto Sainz]
    e.preventDefault();
    setErrorMessage('');
    resetPassword({ password, verifyPassword, id, key }).then(({ error, message }) => {
      if (error) setErrorMessage(message);
      else setIsSubmitted(true);
    });
  }

  if (!id || !key) {
    return (
      <Container>
        <Row>
          <Col md={{ span: 8, offset: 2 }} className="mt-5">
            <div className="text-center">
              <img src={Logo} className="img-fluid" alt="Jet Data logo" />
              <p className="mt-3 text-danger">
                The password link does not appear to be valid. Please ensure the link is not missing any characters. If
                the problem persists, please contact us.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }

  return (
    <Container>
      <Row>
        <Col md={{ span: 8, offset: 2 }} className="mt-5">
          <div className="text-center">
            <img src={Logo} className="img-fluid" alt="Jet Data logo" />
            {isSubmitted ? (
              <>
                <p className="mt-3 text-dark">Your password has been successfully updated. Please login to continue.</p>
                <Link to="/">Back to login.</Link>
              </>
            ) : (
              <p className="mt-3 text-dark">Please reset your password using the form below.</p>
            )}
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={{ span: 8, offset: 2 }}>
          {!isSubmitted && (
            <Form onSubmit={handleSubmit} className="mx-5">
              <Form.Group controlId="resetPassword">
                <Form.Control type="password" placeholder="Password" onChange={e => setPassword(e.target.value)} />
              </Form.Group>
              <Form.Group controlId="verifyPassword">
                <Form.Control
                  type="password"
                  placeholder="Verify Password"
                  onChange={e => setVerifyPassword(e.target.value)}
                />
              </Form.Group>
              <Form.Group>{errorMessage && <Form.Text className="text-danger">{errorMessage}</Form.Text>}</Form.Group>
              <Form.Group className="text-center">
                <Button variant="primary" type="submit">
                  Reset Password
                </Button>
              </Form.Group>
            </Form>
          )}
        </Col>
      </Row>
    </Container>
  );
}

function UnauthenitcatedApp() {
  return (
    <main id="wrapper">
      <div className="vh-100">
        <div className="gray-bg" style={{ minHeight: '85%'}}>
          <Switch>
            <Route path="/" exact={true}>
              <Login />
            </Route>
            <Route path="/forgot-password">
              <ForgotPassword />
            </Route>
            <Route path="/reset-password">
              <ResetPassword />
            </Route>
            <Route path="/invitee/">
              <Invitee />
            </Route>
            <Route path="/shared/:id">
              <SharedPart />
            </Route>
            <Route path="/logout">
              <Redirect to="/" />
            </Route>
            <Route path="*">
              <ErrorPage />
            </Route>
          </Switch>
        </div>
        <div className="gray-bg">
          <CookieBanner
            styles={{
              banner: { backgroundColor: 'rgba(60, 60, 60, 0.05)' },
              message: { color:'#c0c0c0'}
            }}
            message="By using this site you agree to the use of cookies and other technologies to process your personal data."
            onAccept={() => {}}
            cookie="guest-accepted-cookies"
          />
        </div>
      </div>
    </main>
  );
}

export default UnauthenitcatedApp;
