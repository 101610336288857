import React from 'react';
import { Elements, StripeProvider } from 'react-stripe-elements';
import PaymentForm from './PaymentForm';

function Payment() {
  return (
    <StripeProvider stripe={window.Stripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY, { betas: ['payment_intent_beta_3'] })}>
      <Elements>
        <PaymentForm />
      </Elements>
    </StripeProvider>
  );
}

export default Payment;
