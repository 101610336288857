function displayRole(role) {
  if (role === 'admin') return 'Admin'
  if (role === 'manager') return 'Manager'
  if (role === 'member') return 'Member'
}

function displayStatus(status) {
    if (status) return 'Active'
    return 'Inactive'
}

export { displayRole, displayStatus };
