import React from 'react';
import className from 'classnames';

export const Card = ({ children }) => {
  return <div className="ibox full-width">{children}</div>;
};

export const CardTitle = ({ children }) => {
  return <div className="ibox-title">{children}</div>;
};

export const CardBody = ({ children, className, ...props }) => {
  return <div className="ibox-content">{children}</div>;
};

export const CardFooter = ({ children, className, ...props }) => {
  return <div className="ibox-footer">{children}</div>;
};
