import React from 'react';
import { useParams } from 'react-router-dom';
import ReactGA from 'react-ga';
import { getPartOverview, getPartFMVSummary, getPartHotTips } from '../../api/parts-client';
import { useMember } from '../../context/member-context';
import { Spinner } from '../../components/Spinner';
import Overview from './Overview';
import FairMarketValueSummary from './FairMarketValueSummary';
import EventHistory from './EventHistory';
import HotTips from './HotTips';
import { DEFAULT_CONDITION_CODE, DEFAULT_TRANSACTION_TYPE, DEFAULT_DATE_RANGE } from './shared';

import { Container, Modal } from 'react-bootstrap';

// ===== PartDetail Container ====
function PartDetail() {
  // hooks
  const member = useMember();
  const { partNumber, transactionType, conditionCode } = useParams();

  // ui state
  const [isLoading, setIsLoading] = React.useState(true);

  // part domain state
  const [partDetails, setPartDetails] = React.useState(null);
  const [partFMVSummary, setPartFMVSummary] = React.useState(null);
  const [partHotTips, setPartHotTips] = React.useState(null);

  const [selectedTransactionType, setSelectedTransactionType] = React.useState(
    transactionType || DEFAULT_TRANSACTION_TYPE
  );
  const [selectedDateRange, setSelectedDateRange] = React.useState(DEFAULT_DATE_RANGE)
  const [selectedConditionCode, setSelectedConditionCode] = React.useState(conditionCode || DEFAULT_CONDITION_CODE);

  const [showModal, setShowModal] = React.useState(false);
  const [sharedPartUrl, setSharedPartUrl] = React.useState('');
  const inputEl = React.useRef(null);

  // create three refs for the selectedTransactionType, selectedContionCode

  React.useEffect(() => {
    if (member && partNumber) {
      ReactGA.initialize({
        trackingId: process.env.REACT_APP_GA_TRACKING_ID,
        title: 'Part ' + partNumber,
        pageview: window.location.pathname,
        gaOptions: {
          username: member.username
        }
      });
    }
  }, [member, partNumber]);

  React.useEffect(() => {
    let isCurrent = true; // var that prevents work from continuing if re-rendered for some reason.
    Promise.all([getPartOverview(partNumber), getPartFMVSummary(partNumber), getPartHotTips(partNumber)]).then(
      resolvedDataArray => {
        if (!isCurrent) return;

        const [partDetailsData, partFMVSummaryData, partHotTipsData] = resolvedDataArray;
        partDetailsData.transactionType = selectedTransactionType;
        partDetailsData.conditionCode = selectedConditionCode;
        partDetailsData.dateRange = selectedDateRange
        setPartDetails(partDetailsData);
        setPartFMVSummary(partFMVSummaryData);
        setPartHotTips(partHotTipsData);
        setIsLoading(false);
      }
    );

    return () => {
      isCurrent = false;
    };
  }, [partNumber, transactionType, conditionCode]);

  function changeTransactionType(selectedTransactionType) {
    let partDetailsData = partDetails;
    partDetailsData.transactionType = selectedTransactionType;
    setPartDetails(partDetailsData);
  }

  function changeConditionCode(selectedConditionCode) {
    let partDetailsData = partDetails;
    partDetailsData.conditionCode = selectedConditionCode;
    setPartDetails(partDetailsData);
  }

  function changeDateRange(selectedDateRange) {
    let partDetailsData = partDetails;
    partDetailsData.dateRange = selectedDateRange;
    setPartDetails(partDetailsData);
  }

  function handleModalClose() {
    setShowModal(false);
  }

  function copyToClipboardClick() {
    inputEl.current.select();
    document.execCommand('copy');
  }

  return (
    <section className="m-t-md pb-4">
      {isLoading ? (
        <Spinner />
      ) : (
        <Container fluid>
          <Modal onHide={handleModalClose} show={showModal}>
            <Modal.Header closeButton>
              <Modal.Title>Share Part</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <input ref={inputEl} type="text" style={{ width: '90%' }} value={sharedPartUrl} readOnly />
              &#160;
              <button onClick={() => copyToClipboardClick()}>
                <i className="fa fa-copy"></i>
              </button>
            </Modal.Body>
          </Modal>
          <Overview data={partDetails} />
          <FairMarketValueSummary data={partFMVSummary} />
          <EventHistory
            shared={false}
            partNumber={partNumber}
            selectedDateRange={selectedDateRange}
            changeTransactionType={changeTransactionType}
            changeConditionCode={changeConditionCode}
            changeDateRange={changeDateRange}
          />
          <HotTips data={partHotTips} />
        </Container>
      )}
    </section>
  );
}

export default PartDetail;
