import React, { useEffect, useState } from 'react';
import { client } from '../../api/client';
import { Link } from 'react-router-dom';
import { Button, Container, Form, Row, Col, Table } from 'react-bootstrap';
import moment from 'moment';
import { useMember } from '../../context/member-context';
import ReactGA from 'react-ga';
import Mailto from 'reactv16-mailto';

function Licenses() {
  const member = useMember();

  const [availableLicenses, setAvailableLicenses] = useState('1');
  const [totalAvailableLicenses, setTotalAvailableLicenses] = useState('1');
  const [licenses, setLicenses] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [email, setEmail] = useState('');

  useEffect(() => {
    if (member) {
      ReactGA.initialize({
        trackingId: process.env.REACT_APP_GA_TRACKING_ID,
        title: 'Licenses',
        pageview: window.location.pathname,
        gaOptions: {
          username: member.username
        }
      });
    }
  }, [member]);

  useEffect(() => {
    (async () => {
      if (member.role !== 'manager') return setErrorMessage('You are not authorized to access this page');

      const data = await client('licenses');
      if (data.error) return setErrorMessage(data.message);

      setLicenses(data.licenses);
      setAvailableLicenses(data.availableLicenses);
      setTotalAvailableLicenses(data.totalAvailableLicenses);
    })();
  }, [member]);

  async function handleSubmit(e) {
    e.preventDefault();
    setErrorMessage('');
    setSuccessMessage('');

    if (email === '') return setErrorMessage('Please enter an email');
    if (email.length < 6) return setErrorMessage('Please enter a valid email');
    if (email.indexOf('@') < 0 || email.indexOf('.') < 0) {
      return setErrorMessage('Please enter a valid email');
    }

    const emailExists = licenses.filter(m => m.email === email.trim());
    if (emailExists.length !== 0) return setErrorMessage('The email you specified already exists');

    const data = await client('license-send', { body: { email } });

    if (data.error) {
      ReactGA.event({
        category: 'License',
        action: 'Failed Attempt',
        label: member.username
      });

      return setErrorMessage(data.message);
    }

    ReactGA.event({
      category: 'License',
      action: 'Successfully Sent',
      label: member.username
    });

    setEmail('');
    let updatedLicenses = [...licenses];
    updatedLicenses.push(data.license);
    setLicenses(updatedLicenses);
    let remainingLicenses = availableLicenses - 1;
    setAvailableLicenses(remainingLicenses);

    setSuccessMessage('License successfully sent');

    setTimeout(() => {
      setSuccessMessage('');
    }, 1000);
  }

  function displayActions(license) {
    if (typeof license.acceptedDate === 'undefined') {
      return (
        <>
          <span className="link" onClick={() => resendLicense(license)}>
            Resend
          </span>
          &#160;/&#160;
          <span className="link" onClick={() => cancelLicense(license)}>
            Cancel
          </span>
        </>
      );
    } else {
      return 'n/a';
    }
  }

  async function resendLicense(license) {
    setSuccessMessage('');
    const data = await client('license-resend', { body: { id: license.id } });
    if (data.error) {
      ReactGA.event({
        category: 'License',
        action: 'Failed Resend Attempt',
        label: member.username
      });
      return setErrorMessage(data.message);
    }

    ReactGA.event({
      category: 'License',
      action: 'Successfully Resent',
      label: member.username
    });

    setSuccessMessage('License successfully resent');

    setTimeout(() => {
      setSuccessMessage('');
    }, 1000);
  }

  async function cancelLicense(license) {
    const data = await client(`license-cancel`, { body: { id: license.id } });
    if (data.error) {
      ReactGA.event({
        category: 'License',
        action: 'Cancel Attempt Failed',
        label: member.username
      });

      return setErrorMessage(data.message);
    }

    ReactGA.event({
      category: 'License',
      action: 'Successfully Cancelled',
      label: member.username
    });

    let updatedLicenses = [];
    licenses.map((retainLicense, i) => {
      if (license.id !== retainLicense.id) {
        updatedLicenses.push(retainLicense);
      }
    });

    setAvailableLicenses(availableLicenses + 1);
    setLicenses(updatedLicenses);

    setSuccessMessage('License successfully cancelled');

    setTimeout(() => {
      setSuccessMessage('');
    }, 1000);
  }

  function displayAccountStatus(license) {
    if (license.active) return 'Active';
    else if (license.acceptedDate && !license.active) return 'Disabled';
    else return 'Not Created';
  }

  function availableLicensesDisplayMessage() {
    if (availableLicenses === 0) return "You don't have any more available licenses.";
    else if (availableLicenses === 1) return 'You have one available licenses available.';
    else return `You have ${availableLicenses} out of ${totalAvailableLicenses} licenses available.`;
  }

  if (member.role !== 'manager') return <div>{errorMessage}</div>;

  return (
    <div>
      <Form onSubmit={handleSubmit} className="mx-5">
        <Container fluid>
          <Row>
            <Col>
              {availableLicensesDisplayMessage()}
              {availableLicenses === 0 && (
                <>
                  &#160;&#160;
                  <Mailto email={process.env.REACT_APP_CONTACT_EMAIL}>Request more licenses</Mailto>
                </>
              )}
            </Col>
          </Row>
          <Row style={{ height: '25px' }}>
            <Col>
              {errorMessage !== '' && (
                <Form.Group>
                  <Form.Text className="text-danger">{errorMessage}</Form.Text>
                </Form.Group>
              )}
              {successMessage !== '' && (
                <Form.Group>
                  <Form.Text className="text-success">{successMessage}</Form.Text>
                </Form.Group>
              )}
            </Col>
          </Row>
          {availableLicenses !== 0 && (
            <Row>
              <Col lg={6}>
                <Form.Group>
                  <Container>
                    <Row>
                      <Col>
                        <Form.Control
                          type="text"
                          placeholder="Email"
                          value={email}
                          onChange={e => setEmail(e.target.value)}
                        />
                      </Col>
                      <Col>
                        <Button variant="primary" type="submit">
                          Invite
                        </Button>
                      </Col>
                    </Row>
                  </Container>
                </Form.Group>
              </Col>
            </Row>
          )}
          <Row>
            <Col>
              <Table>
                <tbody>
                  <tr>
                    <td colSpan="5">
                      <Link to="/manager/members">Manage Members</Link>
                    </td>
                  </tr>
                  {licenses && licenses.length !== 0 && (
                    <tr>
                      <td>Email</td>
                      <td>Invite Date</td>
                      <td>Accepted Date</td>
                      <td>Account Status</td>
                      <td>Actions</td>
                    </tr>
                  )}
                  {licenses &&
                    licenses.map(license => {
                      return (
                        <tr key={license.id}>
                          <td>{license.email}</td>
                          <td>
                            {moment(license.inviteDate)
                              .format('DDMMMYYYY')
                              .toUpperCase() +
                              ' ' +
                              moment(license.inviteDate).format('hhmm')}
                          </td>
                          <td>
                            {typeof license.acceptedDate !== 'undefined'
                              ? moment(license.acceptedDate)
                                  .format('DDMMMYYYY')
                                  .toUpperCase() +
                                ' ' +
                                moment(license.acceptedDate).format('hhmm')
                              : 'Pending'}
                          </td>
                          <td>{displayAccountStatus(license)}</td>
                          <td>{displayActions(license)}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </Col>
          </Row>
        </Container>
      </Form>
    </div>
  );
}

export default Licenses;
