// TODO: use local storage to mock data base access
export const watchListParts = [
  {
    partNumber: '3289562-5',
    eventVolume: 705,
    companyVolume: 0
  },
  {
    partNumber: '28B545-9',
    eventVolume: 55,
    companyVolume: 0
  }
];
