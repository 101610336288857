import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Card, CardTitle, CardBody } from '../../components/Card';

function HotTips({ data }) {
  const { error, message, payload: hotTips } = data;
  const headerStyle = {
    paddingBottom: 15,
    paddingTop: 15,
    paddingLeft: 15,
    backgroundColor: '#f5f5f5',
    fontWeight: 700
  };

  if (error) {
    return (
      <Card className="m-b-md">
        <CardTitle>
          <h5>Hot Tips</h5>
        </CardTitle>
        <CardBody>{message}</CardBody>
      </Card>
    );
  }

  return (
    <Card className="m-b-md">
      <CardTitle>
        <h5>Hot Tips</h5>
      </CardTitle>
      <CardBody>
        <Row>
          <Col md={12} lg={6} className="mb-4">
            <h5 className="border-bottom" style={headerStyle}>
              Alternates / Mods
            </h5>
            <table className="table m-t-md">
              <tbody>
                {hotTips.alternateTips.length === 0 ? (
                  <tr>
                    <td className="no-borders">No Data Available</td>
                  </tr>
                ) : (
                  <React.Fragment>
                    {hotTips.alternateTips.map((alternateTip, i) => (
                      <tr key={`altTip-${i}`}>
                        <td className="no-borders" dangerouslySetInnerHTML={{ __html: alternateTip }}></td>
                      </tr>
                    ))}
                  </React.Fragment>
                )}
              </tbody>
            </table>
          </Col>
          <Col md={12} lg={6} className="mb-4">
            <h5 className="border-bottom" style={headerStyle}>
              Notes
            </h5>
            <table className="table m-t-md">
              <tbody>
                {hotTips.notes.length === 0 ? (
                  <tr>
                    <td className="no-borders">No Data Available</td>
                  </tr>
                ) : (
                  <React.Fragment>
                    {hotTips.notes.map((note, i) => (
                      <tr key={`notes-${i}`}>
                        <td className="no-borders" dangerouslySetInnerHTML={{ __html: note }}></td>
                      </tr>
                    ))}
                  </React.Fragment>
                )}
              </tbody>
            </table>
          </Col>
        </Row>
        <Row>
          <Col md={12} lg={6} className="mb-4">
            <h5 className="border-bottom" style={headerStyle}>
              Repair / Overhaul Issues
            </h5>
            <table className="table m-t-md">
              <tbody>
                {hotTips.repairOverhaulTips.length === 0 ? (
                  <tr>
                    <td className="no-borders">No Data Available</td>
                  </tr>
                ) : (
                  <React.Fragment>
                    {hotTips.repairOverhaulTips.map((repairOverhaulTip, i) => (
                      <tr key={`repairOverhaulTip-${i}`}>
                        <td className="no-borders" dangerouslySetInnerHTML={{ __html: repairOverhaulTip }}></td>
                      </tr>
                    ))}
                  </React.Fragment>
                )}
              </tbody>
            </table>
          </Col>
          <Col md={12} lg={6} className="mb-4">
            <h5 className="border-bottom active" style={headerStyle}>
              Service Bulletins
            </h5>
            <table className="table m-t-md">
              {hotTips.serviceBullitenTips.length === 0 ? (
                <tbody>
                  <tr>
                    <td className="no-borders">No Data Available</td>
                  </tr>
                </tbody>
              ) : (
                <React.Fragment>
                  <thead>
                    <tr>
                      <th>SB #</th>
                      <th>Rev / #</th>
                      <th>Description</th>
                    </tr>
                  </thead>
                  <tbody>
                    {hotTips.serviceBullitenTips.map((serviceBullitenTip, i) => (
                      <tr key={`serviceBullitenTip-${i}`}>
                        <td className="no-borders">
                          <a href="{{serviceBullitenTip.link}}" target="_blank">
                            {serviceBullitenTip.number}
                          </a>
                        </td>
                        <td className="no-borders">{serviceBullitenTip.revision}</td>
                        <td className="no-borders">{serviceBullitenTip.description}</td>
                      </tr>
                    ))}
                  </tbody>
                </React.Fragment>
              )}
            </table>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
}

export default HotTips;
