import React from 'react';
import {
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ScatterChart,
  Scatter,
  CartesianGrid,
  ReferenceLine,
  ResponsiveContainer,
  ComposedChart,
  Bar,
  Line,
  Cell
} from 'recharts';
import { Form, Row, Col, Button, ButtonGroup, Modal } from 'react-bootstrap';

import { getPartPricingHistory, getPartVolumeHistory } from '../../api/parts-client';
import { Card, CardTitle, CardBody } from '../../components/Card';
import { useMember } from '../../context/member-context';
import {
  formatDate,
  formatNumberToDollarAmount,
  transactionTypes,
  conditionCodes,
  dateRanges,
  DEFAULT_CONDITION_CODE,
  DEFAULT_TRANSACTION_TYPE,
  DEFAULT_DATE_RANGE
} from './shared';

// ========== Helpers ==========
function filterMemberCompanyPricingHistory(events, companyId) {
  return companyId && events && events.length > 0 ? events.filter(event => event.companyId === companyId) : [];
}

const DEFAULT_CHART_HEIGHT = 400;
const DEFAULT_CHART_STYLE = {
  top: 20,
  right: 20,
  bottom: 35,
  left: 35
};
const DEFAULT_DATA_FILL_COLOR = '#32A852';
const DEFAULT_OWN_DATA_FILL_COLOR = '#E02434';
const CHART_HIGHLIGHT_ORANGE = '#E88D1E';
const CHART_HIGHLIGHT_BLUE = '#305DF0';

// ========== UI Components ==========
function CustomTooltip({ active, payload, label, partNumber, transactionType, conditionCode, ...props }) {
  if (active) {
    const { costPerUnit, closeDate } = payload[0].payload;
    return (
      <div
        style={{
          backgroundColor: 'white',
          border: '1px solid  rgba(0, 0, 0, 0.25)',
          boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)'
        }}
      >
        <table>
          <thead>
            <tr>
              <td className="p-w-xs" colSpan="2">
                <strong>{formatDate(closeDate)}</strong>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="p-w-xs">{`${partNumber} / ${transactionType} / ${conditionCode}`}</td>
              <td className="font-weight-bold p-w-xs">{formatNumberToDollarAmount(costPerUnit)}</td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
  return null;
}

class CustomDateAxisTick extends React.PureComponent {
  render() {
    const { x, y, payload } = this.props;
    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-35)">
          {formatDate(payload.value)}
        </text>
      </g>
    );
  }
}

function ChartLoading() {
  return <div style={{ minHeight: DEFAULT_CHART_HEIGHT, padding: 40 }} />;
}

function NoData() {
  return (
    <div style={{ minHeight: DEFAULT_CHART_HEIGHT, padding: 40 }} className="text-center">
      No Data Available for selected filters
    </div>
  );
}

// ========== Charts ==========
function PricingHistoryChart({
  showCompanyData,
  member,
  partNumber,
  selectedTransactionType,
  selectedConditionCode,
  selectedDateRange,
  shared
}) {
  const [pricingHistory, setPricingHistory] = React.useState(null);

  React.useEffect(() => {
    const fetchData = async () => {
      if (!partNumber) return;

      // TODO: Do somthing with error
      const { error, payload } = await getPartPricingHistory({
        partNumber,
        transactionType: selectedTransactionType,
        conditionCode: selectedConditionCode,
        dateRange: selectedDateRange,
        shared
      });

      if (!error) {
        setPricingHistory(payload);
      }
    };

    fetchData();
  }, [partNumber, selectedTransactionType, selectedConditionCode, selectedDateRange, shared]);

  const companyPricingHistory = React.useMemo(() => {
    if (pricingHistory && member) {
      return filterMemberCompanyPricingHistory(pricingHistory.events, member.companyId.id);
    }
    return null;
  }, [pricingHistory, member]);

  if (pricingHistory === null) {
    return <ChartLoading />;
  }

  const { events, average, std } = pricingHistory;
  const upperFMVBound = average !== null && std !== null ? Number(average) + Number(std) : null;
  const lowerFMVBound = average !== null && std !== null ? Math.abs(Number(average) - Number(std)) : null;

  function getLegendPayload() {
    const payload = [
      { type: 'line', color: CHART_HIGHLIGHT_ORANGE, value: 'Upper Bound' },
      { type: 'line', color: DEFAULT_DATA_FILL_COLOR, value: 'FMV (12 months)' },
      { type: 'line', color: CHART_HIGHLIGHT_BLUE, value: 'Lower Bound' },
      {
        type: 'circle',
        color: DEFAULT_DATA_FILL_COLOR,
        value: `${partNumber} / ${selectedTransactionType} / ${selectedConditionCode}`
      }
    ];

    if (showCompanyData) {
      payload.push({
        type: 'circle',
        color: DEFAULT_OWN_DATA_FILL_COLOR,
        value: `${member.companyId.name} / ${partNumber} / ${selectedTransactionType} / ${selectedConditionCode}`
      });
    }

    return payload;
  }

  if (events.length === 0) {
    return <NoData />;
  }

  return (
    <ResponsiveContainer height={DEFAULT_CHART_HEIGHT} width={'100%'}>
      <ScatterChart margin={DEFAULT_CHART_STYLE} id="pricingHistory">
        <CartesianGrid />
        <XAxis dataKey="closeDate" name="Close Date" tick={<CustomDateAxisTick />} allowDuplicatedCategory={false} />
        <YAxis
          lable="Pricing stuff"
          type="number"
          domain={[0, dataMax => (dataMax > upperFMVBound ? dataMax + 1000 : upperFMVBound + 1000)]}
          dataKey="costPerUnit"
          name="Cost Per Unit"
          unit="/USD"
          scale="linear"
          tickFormatter={formatNumberToDollarAmount}
        />
        <Tooltip
          content={
            <CustomTooltip
              partNumber={partNumber}
              transactionType={selectedTransactionType}
              conditionCode={selectedConditionCode}
            />
          }
        />
        <Legend
          margin={{ top: 10, left: 10, right: 10, bottom: 10 }}
          layout="horizontal"
          verticalAlign="top"
          align="right"
          iconSize="10"
          payload={getLegendPayload()}
        />
        <ReferenceLine y={upperFMVBound} stroke={CHART_HIGHLIGHT_ORANGE} />
        <ReferenceLine y={average} stroke={DEFAULT_DATA_FILL_COLOR} />
        <ReferenceLine y={lowerFMVBound} stroke={CHART_HIGHLIGHT_BLUE} />
        <Scatter
          name={`${partNumber} / ${selectedTransactionType} / ${selectedConditionCode}`}
          data={events}
          fill={DEFAULT_DATA_FILL_COLOR}
          isAnimationActive={false}
        />
        {showCompanyData && (
          <Scatter
            name={`${member.companyId.name} / ${partNumber} / ${selectedTransactionType} / ${selectedConditionCode} `}
            data={companyPricingHistory}
            fill={DEFAULT_OWN_DATA_FILL_COLOR}
          />
        )}
      </ScatterChart>
    </ResponsiveContainer>
  );
}

function VolumeHistoryChart({
  showCompanyData,
  showSeasonalTrends,
  member,
  partNumber,
  selectedTransactionType,
  selectedConditionCode
}) {
  const [volumeHistory, setVolumeHistory] = React.useState(null);

  // TODO: make this smarter. Shouldnt fetch for same selectedTransactionType and selectedConditionCode on chartToggle
  React.useEffect(() => {
    const fetchData = async () => {
      const { error, payload } = await getPartVolumeHistory({
        partNumber,
        transactionType: selectedTransactionType,
        conditionCode: selectedConditionCode
      });
      // TODO: Does it make sense to display error on module or as a toaster message?
      if (!error) {
        setVolumeHistory(payload);
      }
    };

    fetchData();
  }, [partNumber, selectedTransactionType, selectedConditionCode]);

  if (!volumeHistory) {
    return <ChartLoading />;
  }

  const { partVolumeByMonth } = volumeHistory;
  if (partVolumeByMonth.length === 0) {
    return <NoData />;
  }

  function getLegendPayload() {
    const payload = [
      {
        type: 'line',
        color: DEFAULT_DATA_FILL_COLOR,
        value: `${partNumber} / ${selectedTransactionType} / ${selectedConditionCode}`
      }
    ];

    if (showSeasonalTrends) {
      payload.unshift({
        type: 'rect',
        color: CHART_HIGHLIGHT_ORANGE,
        value: 'Potential Seasonality'
      });
    }

    if (showCompanyData) {
      payload.push({
        type: 'line',
        color: DEFAULT_OWN_DATA_FILL_COLOR,
        value: `${member.companyId.name} / ${partNumber} / ${selectedTransactionType} / ${selectedConditionCode}`
      });
    }

    return payload;
  }

  return (
    <ResponsiveContainer height={DEFAULT_CHART_HEIGHT} width={'100%'}>
      <ComposedChart data={partVolumeByMonth} margin={DEFAULT_CHART_STYLE} stackOffset="none">
        <CartesianGrid />
        <XAxis dataKey="label" name="By Month" allowDuplicatedCategory={false} padding={{ left: 30, right: 30 }} />
        <YAxis dataKey="volume" name="Event Volume" unit=" events" />
        <Tooltip />
        <Legend
          margin={{ top: 10, left: 10, right: 10, bottom: 10 }}
          layout="horizontal"
          verticalAlign="top"
          align="right"
          iconSize="10"
          payload={getLegendPayload({
            showCompanyData,
            showSeasonalTrends,
            partNumber,
            selectedTransactionType,
            selectedConditionCode,
            companyName: member.companyId.name
          })}
        />
        <Bar
          dataKey="volume"
          barSize={10}
          isAnimationActive={false}
          name={`${partNumber} / ${selectedTransactionType} / ${selectedConditionCode} `}
        >
          {partVolumeByMonth.map((entry, index) => (
            <Cell
              key={`cell-${index}`}
              fill={showSeasonalTrends && entry.potentialSeasonality ? CHART_HIGHLIGHT_ORANGE : DEFAULT_DATA_FILL_COLOR}
            />
          ))}
        </Bar>
        <Line
          dataKey="volume"
          name={`${partNumber} / ${selectedTransactionType} / ${selectedConditionCode} `}
          fill={DEFAULT_DATA_FILL_COLOR}
          stroke={DEFAULT_DATA_FILL_COLOR}
          isAnimationActive={false}
        />
        {showCompanyData && (
          <Line
            dataKey="memberCompanyVolume"
            name={`${member.companyId.name} / ${partNumber} / ${selectedTransactionType} / ${selectedConditionCode}`}
            fill={DEFAULT_OWN_DATA_FILL_COLOR}
            stroke={DEFAULT_OWN_DATA_FILL_COLOR}
            isAnimationActive={false}
          />
        )}
      </ComposedChart>
    </ResponsiveContainer>
  );
}

function EventHistoryHelpModal({ onHide }) {
  return (
    <Modal onHide={onHide} show={true} centered>
      <Modal.Header closeButton>Event History</Modal.Header>
      <Modal.Body>
        TODO:
        <p>Brief description</p>
        <ul>
          <li>Add description on how filters work</li>
          <li>Add description on how "Show Company Data Works"</li>
          <li>Add description on how "Show Seasonality" is calculated (how it will get better with more data)</li>
        </ul>
      </Modal.Body>
      <Modal.Footer>
        <ButtonGroup></ButtonGroup>
      </Modal.Footer>
    </Modal>
  );
}

// ========== Main Exported Module ==========
function EventHistory(props) {
  const member = useMember();
  // ==== State =====
  const [partNumber, setPartNumber] = React.useState(null);
  const [transactionType, setTransactionType] = React.useState(null);
  const [conditionCode, setConditionCode] = React.useState(null);
  const [selectedConditionCode, setSelectedConditionCode] = React.useState(conditionCode || DEFAULT_CONDITION_CODE);
  const [selectedDateRange, setSelectedDateRange] = React.useState(DEFAULT_DATE_RANGE);
  const [showPricingHistory, setShowPricingHistory] = React.useState(true);
  const [showCompanyData, setShowCompanyData] = React.useState(false);
  const [showSeasonalTrends, setShowSeasonalTrends] = React.useState(false);
  const [showHelpModal, setShowHelpModal] = React.useState(false);
  const [shared, setShared] = React.useState(props.shared);

  React.useEffect(() => {
    setPartNumber(props.partNumber);
    setTransactionType(props.transactionType);
    setConditionCode(props.conditionCode);
    setSelectedDateRange(props.selectedDateRange);
  }, [props]);

  const [selectedTransactionType, setSelectedTransactionType] = React.useState(
    transactionType || DEFAULT_TRANSACTION_TYPE
  );

  // ==== event handlers ====
  function toggleActiveChart() {
    setShowPricingHistory(!showPricingHistory);
  }

  function toggleHelpModal() {
    setShowHelpModal(!showHelpModal);
  }

  function changeTransactionType(transactionType) {
    props.changeTransactionType(transactionType);
    setSelectedTransactionType(transactionType);
  }

  function changeConditionCode(conditionCode) {
    props.changeConditionCode(conditionCode);
    setSelectedConditionCode(conditionCode);
  }

  function changeDateRange(dateRange) {
    props.changeDateRange(dateRange);
    setSelectedDateRange(dateRange);
  }

  // ==== render ====
  return (
    <Card className="m-b-md">
      <CardTitle>
        <h5 className="inline-block">Part Event History</h5>
        <div className="ibox-tools">
          <Button variant="link" onClick={toggleHelpModal}>
            <i className="fa fa-question-circle"></i> Explain this chart
          </Button>
        </div>
      </CardTitle>
      <CardBody>
        {showHelpModal && <EventHistoryHelpModal onHide={toggleHelpModal} />}
        {!shared && (
          <>
            <Row>
              <Col md={4}>
                <Form.Group controlId="transactionType">
                  <Form.Label className="font-weight-bold">Transaction Type</Form.Label>
                  <Form.Control
                    as="select"
                    name="transactionType"
                    value={selectedTransactionType}
                    onChange={e => changeTransactionType(e.target.value)}
                  >
                    {transactionTypes.map(({ name, value }) => (
                      <option key={`${name}${value}`} value={value}>
                        {name}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group controlId="conditionCode">
                  <Form.Label className="font-weight-bold">Condition Code</Form.Label>
                  <Form.Control
                    as="select"
                    name="conditionCode"
                    value={selectedConditionCode}
                    onChange={e => changeConditionCode(e.target.value)}
                  >
                    {conditionCodes.map(({ name, value }) => (
                      <option key={`${name}${value}`} value={value}>
                        {name}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col md={4}>
                {showPricingHistory && (
                  <Form.Group controlId="dateRange">
                    <Form.Label className="font-weight-bold">Date Range</Form.Label>
                    <Form.Control
                      as="select"
                      name="dateRange"
                      value={selectedDateRange}
                      onChange={e => changeDateRange(e.target.value)}
                    >
                      {dateRanges.map(({ name, value }) => (
                        <option key={`${name}${value}`} value={value}>
                          {name}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                )}
              </Col>
            </Row>
            <Row>
              <Col>
                <ButtonGroup aria-label="Toogle active chart">
                  <Button variant={showPricingHistory ? 'secondary' : 'outline-secondary'} onClick={toggleActiveChart}>
                    Show Pricing History
                  </Button>
                  <Button variant={showPricingHistory ? 'outline-secondary' : 'secondary'} onClick={toggleActiveChart}>
                    Show Volume History
                  </Button>
                </ButtonGroup>
                <div className="pull-right">
                  {!showPricingHistory && (
                    <Form.Switch
                      inline
                      id="seasonality"
                      label={`Show seasonality`}
                      checked={showSeasonalTrends}
                      onChange={() => setShowSeasonalTrends(!showSeasonalTrends)}
                    />
                  )}
                  <Form.Switch
                    id="company-data"
                    inline
                    label={`Show ${member.companyId ? member.companyId.name : 'Company'} Data`}
                    checked={showCompanyData}
                    onChange={() => setShowCompanyData(!showCompanyData)}
                  />
                </div>
              </Col>
            </Row>
          </>
        )}
        <Row>
          <Col>
            {showPricingHistory ? (
              <PricingHistoryChart
                member={member}
                showCompanyData={showCompanyData}
                partNumber={partNumber}
                selectedTransactionType={selectedTransactionType}
                selectedConditionCode={selectedConditionCode}
                selectedDateRange={selectedDateRange}
                shared={shared}
              />
            ) : (
              <VolumeHistoryChart
                member={member}
                showCompanyData={showCompanyData}
                showSeasonalTrends={showSeasonalTrends}
                partNumber={partNumber}
                selectedTransactionType={selectedTransactionType}
                selectedConditionCode={selectedConditionCode}
              />
            )}
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
}

export default EventHistory;
