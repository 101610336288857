import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import { Card, CardTitle, CardBody } from '../components/Card';
import { useMember } from '../context/member-context'
import { client } from '../api/client';
import Overview from '../pages/PartDetail/Overview';
import EventHistory from '../pages/PartDetail/EventHistory';
import Logo from '../theme/assets/img/brand/jetdata-logo-text.png';
import { transactionTypes, conditionCodeNames, formatNumberToDollarAmount } from './PartDetail/shared';

function SharedPart() {
  const [errorMessage, setErrorMessage] = useState('');
  const { id } = useParams();
  const inputEl = React.useRef(null);
  const [partNumber, setPartNumber] = React.useState('');
  const [partDetails, setPartDetails] = React.useState(null);
  const [partFMVSummary, setPartFMVSummary] = React.useState(null);
  const [conditionCode, setConditionCode] = React.useState('');
  const [transactionType, setTransactionType] = React.useState('');
  const [transactionTypeName, setTransactionTypeName] = React.useState('');
  const [conditionCodeName, setConditionCodeName] = React.useState('');
  const [dateRange, setDateRange] = React.useState('');
  const [fmvAverage, setFmvAverage] = React.useState('');
  const [std, setStd] = React.useState('');

  const member = useMember();
  
  useEffect(() => {
    (async () => {
      let data = await client(`part/shared/${id}`);
      if (data.error) return setErrorMessage(data.message);

      data.payload.shared = true;
      setPartNumber(data.payload.partNumber);
      setConditionCode(data.conditionCode);
      setTransactionType(data.transactionType);
      setDateRange(data.dateRange);
      getTransactionTypeName(data.transactionType);
      getConditionCodeName(data.conditionCode);

      setPartDetails(data);
      data = await client(`part/shared/fmv/${data.payload.partNumber}/${data.conditionCode}/${data.transactionType}`);
      setFmvAverage(data.average);
    })();
  }, []);

  function getTransactionTypeName(transactionType) {
    const transactionTypeName = transactionTypes.filter(t => t.value === transactionType)[0];
    if (transactionTypeName) setTransactionTypeName(transactionTypeName.name);
  }

  function getConditionCodeName(conditionCode) {
    const conditionCodeName = conditionCodeNames.filter(c => c.value === conditionCode)[0];
    if (conditionCodeName) setConditionCodeName(conditionCodeName.name);
  }

  function copyToClipboardClick() {
    inputEl.current.select();
    document.execCommand('copy');
  }

  if (errorMessage) {
    return (
      <Container fluid>
        <Card className="m-b-md">
          <CardBody>
            <Container>
              <Row>
                <Col className="text-center mt-3">
                  {!member && <><a href='https://www.jetdata.io'><img src={Logo} className="img-fluid" alt="Jet Data" /></a><br/><br/><br/></>}

                  <h2>Error</h2>
                  {errorMessage}<br/><br/>
                  <a href='https://www.jetdata.io'>Click Here</a> to go to Jet Data.
                </Col>
              </Row>
            </Container>
          </CardBody>
        </Card>
      </Container>
    );
  }

  return (
    <Container fluid>
      <Card className="m-b-md">
        <CardBody>
          <Container>
            <Row>
              <Col className="text-center mt-3">
                {!member && <><a href='https://www.jetdata.io'><img src={Logo} className="img-fluid" alt="Jet Data" /></a><br /><br /><br /></>}
                <span style={{ fontWeight: 'bold' }}>Copy this link and share with anyone!</span>
                <br />
                <input
                  ref={inputEl}
                  type="text"
                  style={{ width: '425px' }}
                  value={`${process.env.REACT_APP_DOMAIN}shared/${id}`}
                  readOnly
                />
                &#160;
                <button onClick={() => copyToClipboardClick()}>
                  <i className="fa fa-copy"></i> Copy to Clipboard
                </button>
              </Col>
            </Row>
          </Container>
        </CardBody>
      </Card>
      <Row>
        <Col>{partDetails && <Overview data={partDetails} transactionType="" conditionCode="" />}</Col>
      </Row>
      <Row>
        <Col lg={4}>
          <Card className="m-b-md">
            <CardTitle>
              <h5>{transactionTypeName}</h5>
            </CardTitle>
            <CardBody>
              <table className="table table-bordered">
                <thead>
                  <tr className="bg-active">
                    <th>Condition</th>
                    <th>Fair Market Value</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{conditionCodeName}</td>
                    <td>{formatNumberToDollarAmount(fmvAverage)}</td>
                  </tr>
                </tbody>
              </table>
            </CardBody>
          </Card>
        </Col>
        <Col lg={8}>
          <EventHistory
            shared={true}
            partNumber={partNumber}
            selectedTransactionType={transactionType}
            selectedConditionCode={conditionCode}
            selectedDateRange={dateRange}
          />
        </Col>
      </Row>
    </Container>
  );
}

export default SharedPart;
