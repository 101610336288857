import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { client } from '../../api/client';
import { useHistory } from 'react-router-dom';
import { Button, Container, Form, Row, Col } from 'react-bootstrap';
import { useMember } from '../../context/member-context';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function AdminCreateMember() {
  const member = useMember();
  const history = useHistory();
  const query = useQuery();

  const [admins, setAdmins] = useState([]);
  const [parentId, setParentId] = useState('');
  const [companyId, setCompanyId] = useState('');
  const [allDepts, setAllDepts] = useState([]);
  const [deptId, setDeptId] = useState(query.get('deptId'));
  const [errorMessage, setErrorMessage] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');
  const [verifyPassword, setVerifyPassword] = useState('');
  const [displayRole, setDisplayRole] = useState('');
  const [companies, setCompanies] = useState([]);
  const [depts, setDepts] = useState([]);
  const [role, setRole] = useState(query.get('role'));

  useEffect(() => {
    (async () => {
      if (member.role !== 'admin') return setErrorMessage('You are not authorized to access this page');

      const [membersData, companiesData, deptsData] = await Promise.all([
        client('admin/admins'),
        client(`admin/companies`),
        client('admin/depts')
      ]);

      setAdmins(membersData.members);
      setCompanies(companiesData.companies);
      setAllDepts(deptsData.depts);
      setDepts(deptsData.depts);
      
      deptsData.depts.map(dept => {
        if (deptId.toString() === dept.id.toString()) {
          setCompanyId(dept.companyId);
        }
      });
    })();
  }, []);

  function setSelectedParent(e) {
    setParentId(e.target.value);
  }

  function setSelectedRole(e) {
    setRole(e.target.value);
  }

  function setSelectedCompany(e) {
    setCompanyId(e.target.value);
    setDeptId('');

    let depts = [];
    setDepts([]);
    allDepts.map(dept => {
      if (dept.companyId === e.target.value) {
        depts.push(dept);
      }
    });
    setDepts(depts);
  }

  function setSelectedDept(e) {
    setDeptId(e.target.value);
  }

  async function handleSubmit(e) {
    e.preventDefault();
    setErrorMessage('');

    if (parentId === '') return setErrorMessage('Please select a manager');
    if (role === '') return setErrorMessage('Please select a role');
    if (companyId === '') return setErrorMessage('Please select a company');
    if (deptId === '') return setErrorMessage('Please select a dept');
    if (firstName === '') return setErrorMessage('Please enter a first name');
    if (lastName === '') return setErrorMessage('Please enter a last name');
    if (email === '') return setErrorMessage('Please enter an email');
    if (password === '') return setErrorMessage('Please enter a password');
    if (verifyPassword === '') return setErrorMessage('Please verify the password');
    if (password !== verifyPassword) return setErrorMessage("Passwords specified don't match");

    const data = await client('admin/create-member', {
      body: { parentId, companyId, deptId, email, password, firstName, lastName, phone, role }
    });
    if (data.error) return setErrorMessage(data.message);

    if (role === 'manager') {
      history.push(`/admin/payment/${data.member.id}`);
    } else {
      history.push(`/admin/company/${companyId}`);
    }
  }

  if (member.role !== 'admin') return <div>{errorMessage}</div>;

  return (
    <div>
      <Form onSubmit={handleSubmit} className="mx-5">
        <Container>
          <Row style={{ height: '25px' }}>
            <Col>
              <Form.Group>{errorMessage && <Form.Text className="text-danger">{errorMessage}</Form.Text>}</Form.Group>
            </Col>
          </Row>
          <Row>
            <Col lg={3}>
              <Form.Group>
                <Form.Label>Manager</Form.Label>
                <Form.Control as="select" onChange={e => setSelectedParent(e)}>
                  <option value="">Please Select</option>
                  {admins &&
                    admins.map(admin => {
                      return (
                        <option key={`admin_${admin.id}`} value={admin.id}>
                          {admin.firstName + ' ' + admin.lastName}
                        </option>
                      );
                    })}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col lg={3}>
              <Form.Group>
                <Form.Label>Role</Form.Label>
                <Form.Control as="select" onChange={e => setSelectedRole(e)} value={role}>
                  <option value="">Please Select</option>
                  <option value="manager">Manager</option>
                  <option value="member">Member</option>
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col lg={3}>
              <Form.Group>
                <Form.Label>Company</Form.Label>
                <Form.Control as="select" onChange={e => setSelectedCompany(e)} value={companyId}>
                  <option value="">Please Select</option>
                  {companies &&
                    companies.length !== 0 &&
                    companies.map(company => {
                      return (
                        <option key={`company_${company.id}`} value={company.id}>
                          {company.name}
                        </option>
                      );
                    })}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col lg={3}>
              <Form.Group>
                <Form.Label>Dept</Form.Label>
                <Form.Control as="select" onChange={e => setSelectedDept(e)} value={deptId}>
                  <option>Please Select</option>
                  {depts &&
                    depts.length !== 0 &&
                    depts.map(dept => {
                      return (
                        <option key={`dept_${dept.id}`} value={dept.id}>
                          {dept.name}
                        </option>
                      );
                    })}
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col lg={3}>
              <Form.Group>
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  type="text"
                  name="firstName"
                  autoComplete="off"
                  placeholder="First Name"
                  value={firstName}
                  onChange={e => setFirstName(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col lg={3}>
              <Form.Group>
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                  type="text"
                  name="lastName"
                  autoComplete="off"
                  placeholder="Last Name"
                  value={lastName}
                  onChange={e => setLastName(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col lg={3}>
              <Form.Group>
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="text"
                  autoComplete="off"
                  placeholder=""
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col lg={3}>
              <Form.Group>
                <Form.Label>Phone</Form.Label>
                <Form.Control
                  type="text"
                  autoComplete="off"
                  placeholder=""
                  value={phone}
                  onChange={e => setPhone(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col lg={3}>
              <Form.Group>
                <Form.Label>Password (min 6 chars)</Form.Label>
                <Form.Control
                  type="password"
                  autoComplete="off"
                  placeholder=""
                  value={password}
                  onChange={e => setPassword(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col lg={3}>
              <Form.Group>
                <Form.Label>Verify Password</Form.Label>
                <Form.Control
                  type="password"
                  autoComplete="off"
                  placeholder=""
                  value={verifyPassword}
                  onChange={e => setVerifyPassword(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Button variant="primary" type="submit">
                Create {displayRole}
              </Button>
            </Col>
          </Row>
        </Container>
      </Form>
    </div>
  );
}

export default AdminCreateMember;
