import { client, getToken, removeToken } from './client';
import ReactGA from 'react-ga';

function initAppData() {
  const token = getToken();
  if (!token) {
    return Promise.resolve({ error: false, payload: null });
  }

  return client('member').then(({ error, message, payload }) => {
    if (error) {
      logout();
    }
    return { error, message, payload };
  });
}

function login(credentials) {
  return client('login', { body: credentials }).then(({ error, message, payload }) => {
    if (error) {
      return { payload: null, error, message };
    }

    return { payload, error, message, url: payload.role === 'admin' ? '/admin' : '/' };
  });
}

function logout(memberId) {
  return client('logout', { body: { memberId } }).then(data => {
    ReactGA.event({
      category: 'Logout',
      action: 'Successfully Logged Out'
    });

    removeToken();
    return data;
  });
}

function register(registrationForm) {
  return client('register', { body: registrationForm }).then(({ error, message, member }) => {
    return { member: error ? null : member, error, message };
  });
}

function licenseRegister(registrationForm) {
  return client('license-register', { body: registrationForm }).then(({ error, message, member }) => {
    return { member: error ? null : member, error, message };
  });
}

function forgotPassword({ email }) {
  return client('password-reset-request', { body: { email } });
}

function resetPassword({ password, verifyPassword, id, key }) {
  return client('password-reset', { body: { password, verifyPassword, id, key } });
}

export { login, logout, register, licenseRegister, initAppData, forgotPassword, resetPassword };
