import React from 'react';
import { Elements, StripeProvider } from 'react-stripe-elements';
import AddCardForm from './AddCardForm';

function AddCard() {
  return (
    <StripeProvider stripe={window.Stripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY, { betas: ['payment_intent_beta_3'] })}>
      <Elements>
        <AddCardForm />
      </Elements>
    </StripeProvider>
  );
}

export default AddCard;
