import React from 'react';

function Footer() {
  function getYear() {
    const currentDate = new Date();
    return currentDate.getFullYear();
  }

  return (
    <div className="footer">
      <div className="pull-right">
        <strong>&copy;</strong> Jet Data {getYear()}
      </div>
    </div>
  );
}

export default Footer;
